import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Scrollbars from "react-custom-scrollbars";
import { CiCamera } from "react-icons/ci";
import {
  createGroupChat,
  getFollowOrFollowingList,
} from "../utilities/ApiCalls";
import { getTokenDetails } from "../utilities/CommonFunction";

const PopoverComponent = ({ visible, onClose }) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPics, setSelectedPics] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupIcon, setGroupIcon] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isGroupCreation, setIsGroupCreation] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let token = localStorage.getItem("loginDetails");
        let res = {};
        if (token) {
          res = await getTokenDetails(token);
        }

        const userId = res._id;

        // Fetch following list
        const followingMetaData = { user_id: userId, type: 1 };
        const followingResponse = await getFollowOrFollowingList(
          followingMetaData
        );

        // Fetch followers list
        const followersMetaData = { user_id: userId, type: 2 };
        const followersResponse = await getFollowOrFollowingList(
          followersMetaData
        );

        const combinedList = [
          ...followingResponse.data.map((user) => ({ ...user, type: 1 })), // Mark as following
          ...followersResponse.data.map((user) => ({ ...user, type: 2 })), // Mark as followers
        ];

        const uniqueUsers = [];
        const uniquePairs = new Set();

        combinedList.forEach((user) => {
          const forwardPair = `${user.followerId}-${user.followingId}`;
          const reversePair = `${user.followingId}-${user.followerId}`;

          if (!uniquePairs.has(forwardPair) && !uniquePairs.has(reversePair)) {
            uniqueUsers.push(user);
            uniquePairs.add(forwardPair);
          }
        });

        setUsers(uniqueUsers);
      } catch (error) {
        console.error("Error fetching users", error);
      }
    };

    fetchUsers();
  }, []);

  if (!visible) return null;

  const handleUserSelect = (user) => {
    const userId = user.type === 1 ? user.followingId : user.followerId;

    setSelectedUsers((selectedUsers) =>
      selectedUsers.includes(userId)
        ? selectedUsers.filter((id) => id !== userId)
        : [...selectedUsers, userId]
    );

    setSelectedPics((selectedPics) =>
      selectedUsers.includes(userId)
        ? selectedPics.filter((pic) => pic !== user.profilePic)
        : [...selectedPics, user.profilePic]
    );
  };

  const handleNext = () => {
    if (selectedUsers.length > 1) {
      setIsGroupCreation(true);
    } else {
      handleSubmit();
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setGroupIcon(URL.createObjectURL(file)); // Create a URL for the uploaded image
    }
  };

  const handleSubmit = async () => {
    console.log("Selected user list is", selectedUsers);
    const groupData = {
      users: selectedUsers,
      chatName: groupName || null,
      groupDescription: groupDescription || null,
      groupImage: groupIcon || null,
    };

    try {
      createGroupChat(groupData);
      console.log("groupData", groupData);
      onClose(); // Close the popover after successful submission
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <PopoverContent>
      {!isGroupCreation ? (
        <>
          Add Members
          <SearchInput
            type="text"
            placeholder="Search users"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <SelectedPicsContainer>
            Selected users:
            {selectedPics.map((pic, index) => (
              <SelectedPic key={index} src={pic} alt="Selected User" />
            ))}
          </SelectedPicsContainer>
          <UserList>
            {filteredUsers.map((user) => {
              const userId =
                user.type === 1 ? user.followingId : user.followerId;
              return (
                <UserItem key={user._id}>
                  <ProfileImage src={user.profilePic} alt={user.name} />
                  <UserName>{user.name}</UserName>
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(userId)}
                    onChange={() => handleUserSelect(user)}
                  />
                </UserItem>
              );
            })}
          </UserList>
          <NextButton
            disabled={selectedUsers.length === 0}
            onClick={handleNext}
          >
            Next
          </NextButton>
        </>
      ) : (
        <>
          Create group
          <div>
            <UploadLabel htmlFor="groupIcon">
              {groupIcon ? (
                <UploadedImage src={groupIcon} alt="Group Icon" />
              ) : (
                <CiCamera size={24} />
              )}
            </UploadLabel>
            <FileInput
              type="file"
              id="groupIcon"
              accept="image/*"
              onChange={handleImageUpload}
            />
          </div>
          <Input
            type="text"
            placeholder="Group Name (Optional)"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
          <Input
            type="text"
            placeholder="Group Description (Optional)"
            value={groupDescription}
            onChange={(e) => setGroupDescription(e.target.value)}
          />
          <SelectedPicsContainer>
            Selected Users:
            {selectedPics.map((pic, index) => (
              <SelectedPic key={index} src={pic} alt="Selected User" />
            ))}
          </SelectedPicsContainer>
          <CreateButton onClick={handleSubmit}>Create Group</CreateButton>
        </>
      )}
    </PopoverContent>
  );
};

export default PopoverComponent;

// Styled components

const PopoverContent = styled.div`
  position: absolute;
  top: 40px;
  left: 80%;
  width: 120%;
  padding: 20px;
  background-color: #0c343a;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
`;

const SelectedPicsContainer = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  overflow-x: auto;
`;

const SelectedPic = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
`;
const SearchInput = styled.input`
  width: 100%;
  padding: 6px;
  height: 30px;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
`;

const UserList = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
`;

const UserItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
`;

const UserName = styled.span`
  flex-grow: 1;
  font-size: 14px;
`;

const NextButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#0c343a")};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: 14px;
`;

const UploadLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-top: 5px;
  background-color: #d3d3d3; /* Light gray background */
  border: 2px solid black; /* Black border */
  border-radius: 50%; /* Circular shape */
  overflow: hidden; /* Crop image to fit circle */
`;

const FileInput = styled.input`
  display: none; /* Hide the default file input */
`;

const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the circle */
`;

const Input = styled.input`
  width: 90%;
  padding: 6px;
  height: 30px;
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
`;

const CreateButton = styled.button`
  background-color: #0c343a;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
`;

import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Toaster, toast } from "sonner";
import {
  getOpenMicEvents,
  getOpenMicContests,
  getOpenMicContestsById,
  getAllContests,
  getOfflineOpenMics,
} from "./utilities/ApiCalls";
import { getTokenDetails } from "./utilities/CommonFunction";
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

const Constant = require("./Constant");

const Events = () => {
  const [t] = useTranslation();
  const [openMicEvents, setOpenMicEvents] = useState([]);
  const [openMic, setOpenMic] = useState([]);
  const [offlineOpenMics, setOfflineOpenMics] = useState([]);
  const [contestData, setContestData] = useState([]);
  const [error, setError] = useState(null);
  const [offlineError, setOfflineError] = useState(null);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [meetlinks, setMeetLinks] = useState({});
  const [popup, setPopup] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [showmeet, setShowMeet] = useState(false);

  const [currentSlide, setCurrentSlide] = useState({
    events: 0,
    openMic: 0,
    contests: 0,
    offlineOpenMics: 0,
  });
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return format(date, "do MMM yyyy 'at' hh.mm aaaa ('IST')", {
      locale: enUS,
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("loginDetails");
    console.log(token);
    const fetchStream = async () => {
      try {
        const { data } = await axios.get(
          `${Constant.apiBasePath}openMic/getContestLinks`,
          {
            headers: { token: token },
          }
        );
        await setMeetLinks(data.data[0]);
        console.log(data.data[0]);
      } catch (error) {
        console.error("Error fetching user stream:", error);
        setErrorMessage(error.message);
      }
    };

    fetchStream();
  }, []);

  const handleJoin = () => {
    // You can add passcode validation logic here if needed
    if (passcode === meetlinks.codeZoomLink) {
      toast("correct passcode");
      setPasscode("");
      setShowMeet(true);
    } else {
      toast("wrong passcode try again ");
      setPasscode("");
    }
  };

  // Function to combine date and time into a Date object
  const getDateTime = (date, time) => {
    const [year, month, day] = date.split("-").map(Number);
    const [hour, minute] = time.split(":").map(Number);
    return new Date(year, month - 1, day, hour, minute);
  };

  // Function to sort contests
  const sortContests = (contests) => {
    return contests.sort((a, b) => {
      const dateTimeA = getDateTime(a.startDate, a.startTime);
      const dateTimeB = getDateTime(b.startDate, b.startTime);
      return dateTimeB - dateTimeA; // Descending order
    });
  };

  useEffect(() => {
    const fetchOpenMicEvents = async () => {
      try {
        console.log("Fetching Koral Open Mics...");
        const openMicEventsResult = await getOpenMicEvents();
        console.log("Koral Open Mics Response:", openMicEventsResult);

        if (openMicEventsResult.status === "Success") {
          setOpenMicEvents(openMicEventsResult.data);
        } else {
          setError(openMicEventsResult.message);
        }
      } catch (err) {
        setError("Error fetching Koral Open Mic events: " + err.message);
        console.error("Error fetching Koral Open Mic events:", err);
      }
    };
    const sortByDate = (data) => {
      return data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    };

    const fetchOpenMicContests = async () => {
      try {
        const openMicContestsResult = await getOpenMicContests();
        if (openMicContestsResult.status === "Success") {
          console.log("Open Mic Events Set:", openMicContestsResult.data); // Log the data
          const sortedContests = openMicContestsResult.data.sort((a, b) => {
            const dateTimeA = new Date(`${a.createdAt}`);
            const dateTimeB = new Date(`${b.createdAt}`);
            return dateTimeB - dateTimeA;
          });
          setOpenMic(sortedContests);
          console.log("sorted ", sortedContests);
        } else {
          setError(openMicContestsResult.message);
        }
      } catch (err) {
        setError("Error fetching Open Mic contests: " + err.message);
      }
    };

    const fetchOfflineOpenMics = async () => {
      try {
        console.log("Fetching Offline Open Mics...");
        const offlineOpenMicsResult = await getOfflineOpenMics();
        console.log("Offline Open Mics Response:", offlineOpenMicsResult);

        const activeOfflineOpenMics = offlineOpenMicsResult.data.filter(
          (mic) => mic.active === 1
        );
        setOfflineOpenMics(activeOfflineOpenMics);
      } catch (err) {
        setOfflineError("Error fetching Offline Open Mics: " + err.message);
        console.error("Error fetching Offline Open Mics:", err);
      }
    };

    const fetchContests = async () => {
      try {
        console.log("Fetching Contests...");
        const allContestsResult = await getAllContests({});
        console.log("Contests Response:", allContestsResult);

        if (allContestsResult.status === "Success") {
          const sortedContests = allContestsResult.data.sort((a, b) => {
            const dateTimeA = new Date(`${a.startDate}T${a.startTime}`);
            const dateTimeB = new Date(`${b.startDate}T${b.startTime}`);
            return dateTimeB - dateTimeA;
          });
          setContestData(sortedContests);
        }
      } catch (err) {
        setError("Error fetching contests: " + err.message);
        console.error("Error fetching contests:", err);
      }
    };

    fetchOpenMicEvents();
    fetchOpenMicContests();
    fetchOfflineOpenMics();
    fetchContests();
  }, []);

  const handleContestClick = async (
    languageu,
    contestId,
    contestName,
    contestType
  ) => {
    const contest = contestData.find((c) => c._id === contestId);
    if (contest) {
      const startDateTime = new Date(
        `${contest.startDate}T${contest.startTime}`
      );
      const language = languageu || "English";
      const closureDateTime = new Date(
        `${contest.closureDate}T${contest.closureTime}`
      );
      const resultDateTime = new Date(
        `${contest.resultDate}T${contest.resultTime}`
      );
      const currentDateTime = new Date();

      if (
        currentDateTime >= closureDateTime &&
        currentDateTime <= resultDateTime
      ) {
        history.push({
          pathname:
            contestType === "story"
              ? `/contestStories/${contestId}`
              : `/contestPoetries/${contestId}`,
          search: `?resultDate=${resultDateTime}`,
        });
      } else if (
        currentDateTime >= startDateTime &&
        currentDateTime <= closureDateTime
      ) {
        const token = localStorage.getItem("loginDetails");
        const userDetails = token ? getTokenDetails(token) : {};
        try {
          const endpoint =
            contestType === "story" ? "story/filter" : "poem/filter";

          const response = await axios.get(
            `${Constant.apiBasePath}${endpoint}?contestId=${contestId}`
          );

          const stories = response.data;
          const userIdMatched = stories.some(
            (story) => story.userId === userDetails._id
          );
          if (userIdMatched) {
            setErrorMessage("Story already submitted");

            setTimeout(() => {
              setErrorMessage("");
            }, 3000);
          } else {
            history.push(
              contestType === "story"
                ? `/contest-story?contestId=${contestId}&language=${language}`
                : `/contest-poem?contestId=${contestId}&language=${language}`
            );
          }
        } catch (error) {
          console.error("Error fetching stories:", error);
        }
      } else if (currentDateTime > resultDateTime) {
        history.push({
          pathname: `/contest-result/${contestId}/${contestType}`,
          state: { contestName },
        });
      }
    }
  };

  const handleAfterChange = (carousel, index) => {
    setCurrentSlide((prevState) => ({
      ...prevState,
      [carousel]: index,
    }));
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
  };

  const formUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSc6pj6ODipF2efqVT_sKODwsc0VNWuto5jjMFYe7_WMMA68aw/closedform";

  return (
    <Layout>
      <Container>
        {/* <Section>
          <Heading>Open Mic Offline Event</Heading>
          {error ? (
            <Error>{error}</Error>
          ) : (
            <SliderContainer>
              <Slider
                {...settings}
                afterChange={(index) =>
                  handleAfterChange("offlineOpenMics", index)
                }
              >
                {offlineOpenMics.map((mic) => (
                  <SliderItem>
                    <Link to={`/OpenMicOfflineParticipantForm/?id=${mic._id}`}>
                      <Image
                        src="https://cdn-icons-png.freepik.com/512/7067/7067447.png"
                        alt={mic.name}
                      />
                      <Title>{mic.name}</Title>
                      <Subtitle>{mic.location}</Subtitle>
                    </Link>
                  </SliderItem>
                ))}
              </Slider>
            </SliderContainer>
          )}
        </Section> */}
        {meetlinks.active && (
          <StreamSection>
            <div className="livestream">
              <h1>LiveStreaming</h1>
              {meetlinks.livestreamingLink ? (
                <iframe
                  className="video"
                  src={`https://www.youtube.com/embed/${meetlinks.livestreamingLink}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <p>Loading livestream...</p>
              )}

              <button className="joinButton" onClick={() => setPopup(true)}>
                Join
              </button>
            </div>

            {/* Popup for passcode */}
            {popup && (
              <div className="popup">
                <div className="popup-content">
                  {showmeet ? (
                    <div className="link" style={{ padding: "20px" }}>
                      <p style={{ fontSize: "20px", color: "white" }}>
                        Meet Link :{" "}
                        <a href={`${meetlinks.zoomLink}`} target="_blank">
                          {meetlinks.zoomLink}
                        </a>
                      </p>
                      <button
                        className="clbtn"
                        onClick={() => (setPopup(false), setShowMeet(false))}
                      >
                        Close
                      </button>
                    </div>
                  ) : (
                    <>
                      <h2>Enter Passcode</h2>
                      <input
                        type="text"
                        value={passcode}
                        onChange={(e) => setPasscode(e.target.value)}
                        placeholder="Enter passcode"
                      />
                      <div className="btnContainer">
                        <button className="btn" onClick={handleJoin}>
                          Submit
                        </button>
                        {errorMessage && (
                          <p style={{ color: "red" }}>{errorMessage}</p>
                        )}
                        <button className="btn" onClick={() => setPopup(false)}>
                          Close
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </StreamSection>
        )}
        <Section>
          <Heading>{t("Events.Contests")}</Heading>
          {errorMessage && <Error>{errorMessage}</Error>}
          <SliderContainer>
            <Slider
              {...settings}
              afterChange={(index) => handleAfterChange("contests", index)}
            >
              {contestData.map((contest, index) => {
                const currentDateTime = new Date();
                const startDateTime = new Date(
                  `${contest.startDate}T${contest.startTime}`
                );
                const closureDateTime = new Date(
                  `${contest.closureDate}T${contest.closureTime}`
                );
                const resultDateTime = new Date(
                  `${contest.resultDate}T${contest.resultTime}`
                );

                return (
                  <SliderItem
                    key={contest._id}
                    onClick={() =>
                      handleContestClick(
                        contest.language,
                        contest._id,
                        contest.name,
                        contest.type
                      )
                    }
                  >
                    <Image
                      src={
                        contest.image ||
                        "https://images.unsplash.com/photo-1485579149621-3123dd979885?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      }
                      alt={contest.name}
                    />
                    <Title>{contest.name}</Title>
                    <Subtitle>
                      {currentDateTime >= startDateTime &&
                      currentDateTime <= closureDateTime ? (
                        // Contest is live
                        <span>{t("Events.ContestIsLive")}</span>
                      ) : currentDateTime > closureDateTime &&
                        currentDateTime < resultDateTime ? (
                        // Voting period between closureDateTime and resultDateTime
                        <span>{t("Events.VotingPeriod")}</span>
                      ) : currentDateTime < startDateTime ? (
                        // Contest has not started yet
                        <span>
                          {t("Events.ContestStartOn")}{" "}
                          {formatDateTime(
                            contest.startDate + " " + contest.startTime
                          )}
                        </span>
                      ) : (
                        // Contest has ended
                        <span>{t("Events.ContestEnd")}</span>
                      )}
                    </Subtitle>
                  </SliderItem>
                );
              })}
            </Slider>
          </SliderContainer>
        </Section>

        <Section>
          <Heading>{t("Events.KoralOpenMics")}</Heading>
          {error ? (
            <Error>{error}</Error>
          ) : (
            <SliderContainer>
              <Slider
                {...settings}
                afterChange={(index) => handleAfterChange("events", index)}
              >
                {openMicEvents.map((event, index) => (
                  <SliderItem
                    key={event._id}
                    onClick={() =>
                      window.open(event.url, "_blank", "noopener,noreferrer")
                    }
                  >
                    <Image
                      src="https://t3.ftcdn.net/jpg/01/15/40/12/360_F_115401245_GYPbMWpOT59rUcfObToRcFjyF2KFYIAe.jpg"
                      alt={event.name}
                    />
                    <Title>{event.name}</Title>
                  </SliderItem>
                ))}
              </Slider>
            </SliderContainer>
          )}
        </Section>

        <Section>
          <Heading>{t("Events.Events")}</Heading>
          <SliderContainer>
            <Slider
              {...settings}
              afterChange={(index) => handleAfterChange("openMic", index)}
            >
              {openMic.map((data, index) => (
                <Link to={`/openmic/${data._id}`} key={data._id}>
                  <SliderItem>
                    <Image
                      src="https://images.unsplash.com/photo-1485579149621-3123dd979885?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                      alt={data.name}
                    />
                    <Title>{data.name}</Title>
                  </SliderItem>
                </Link>
              ))}
            </Slider>
          </SliderContainer>
        </Section>
        <Toaster />
      </Container>
    </Layout>
  );
};
const SliderItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
  width: 100%; /* Ensure it takes full width */
  height: 400px; /* Fixed height for larger screens */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    height: 300px; /* Adjust height for tablets */
  }

  @media (max-width: 480px) {
    height: 300px; /* Adjust height for mobile devices */
    width: 100%; /* Ensure the width is full on mobile */
    border-radius: 8px; /* Smaller border radius for mobile */
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  &.active {
    transform: scale(1.05);
    z-index: 1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  &:hover {
    opacity: 0.9;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 12px;

  @media (max-width: 768px) {
    height: 200px;
    width: 100%;
  }
`;

const Title = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 1em; /* Adjust font size for smaller screens */
    left: 10px; /* Adjust position for smaller screens */
    bottom: 50px; /* Ensure it doesn't overlap with the subtitle */
  }
`;

const Subtitle = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-size: 0.9em;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 0.8em; /* Adjust font size for smaller screens */
    left: 10px; /* Adjust position for smaller screens */
    bottom: 10px; /* Ensure it fits well with the title */
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  background: #0f1817;
  box-sizing: border-box;
`;

const Section = styled.section`
  margin-bottom: 60px;
`;
const StreamSection = styled.section`
  margin-bottom: 60px;
  width: 50%; /* Default width for larger screens */
  margin: 0 auto;

  input {
    display: inline-block;
    width: 80%;
    height: 25%;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    letter-spacing: 1px;
  }
  p {
    text-align: center;
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Reduced opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top of everything */
  }
  .btnContainer {
    display: flex;
    gap: 28px;
    justifycontent: center;
    alignitems: center;
    paddingtop: 10px;
  }
  .popup-content {
    background-color: #0f1817;
    width: 40%;
    height: 24%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;

    .clbtn {
      width: 40%;
      height: 5vh;
      border: 1px solid gray;
      background-color: white;
      border-radius: 10px;
    }

    .btn {
      width: 100%;
      height: 5vh;
      border: 1px solid gray;
      background-color: white;
      border-radius: 10px;
    }
  }

  /* Input styling */

  .livestream {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
  }

  .joinButton {
    width: 7vw;
    height: 4vh;
    font-weight: bold;
    background: #212121;
    color: #ffffff;
    border: 1px solid black;
    border-radius: 5px;
  }
  .video {
    width: 566px;
    height: 400px;
  }
  .link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    width: 70%; /* Adjust width for tablets if needed */

    .btnContainer {
      gap: 28px;
    }

    .popup-content {
      width: 62%;
      height: 25%;

      .btn {
        width: 70%;
        height: 6vh;
      }
    }
    input {
      height: 27%;
    }

    .video {
      width: 120%;
      height: 400px;
    }

    .joinButton {
      width: 13vw;
      height: 4vh;
    }
  }

  @media (max-width: 480px) {
    width: 80%; /* Adjust width for mobile devices */
    .btnContainer {
      gap: 28px;
    }

    .popup-content {
      width: 70%;
      height: 25%;

      .btn {
        width: 70%;
        height: 6vh;
      }
    }

    input {
      height: 30%;
    }
    .video {
      width: 120%;
      height: 300px;
    }

    .joinButton {
      width: 13vw;
      height: 4vh;
    }
  }
`;

const Heading = styled.h1`
  font-size: 2.2em;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: red;
  text-align: center;
  font-size: 1.3em;
  margin: 40px 0;
`;

const SliderContainer = styled.div`
  width: 50%; /* Default width for larger screens */
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 70%; /* Adjust width for tablets if needed */
  }

  @media (max-width: 480px) {
    width: 80%; /* Adjust width for mobile devices */
  }
`;

export default Events;

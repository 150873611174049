import {
  Bell,
  Calendar,
  Compass,
  Expand,
  LogOut,
  MessageSquare,
  Plus,
  ScanSearch,
  Search,
  SearchCheck,
  SearchIcon,
  Settings,
  Settings2,
} from "lucide-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoCreate } from "react-icons/io5";
import Image from "../Images/Defaultprofile.png";
import Logo from "../Images/Logonew.jpeg";
import styled from "styled-components";
import SearchModalComponent from "../Componets/SearchModal";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getPoems,
  getProfileDetails,
  getStories,
  getUserByName,
  getNotificationForUser,
  getUnreadNotificationCount,
  getUnreadMessageCount,
} from "../views/utilities/ApiCalls";
import { statusFailure } from "../views/Constant";
import { useTranslation } from "react-i18next";
import Context from "../Context";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useModel } from "../hooks/modelcontex";
import Default from "../Images/Defaultprofile.png";
import { getTokenDetails } from "../views/utilities/CommonFunction";

export const NewAuthNav = () => {
  const { state, dispatch } = useContext(Context);
  const [profile, setProfile] = useState({});
  const location = useLocation();
  const [error, setError] = useState(null);
  const history = useHistory();
  const [messageTitle, setMessageTitle] = useState("");
  const { i18n, t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const [drop, setdrop] = useState();
  const [users, setusers] = useState([]);
  const [story, setstory] = useState([]);
  const [poem, setpoem] = useState([]);
  const { Profilepic, profilePic } = useModel();
  const [search, setSearch] = useState(false);

  const { Myprofile, myprofile } = useModel();

  const [form, setForm] = useState({
    search_type: "story",
    search: "",
  });

  const { SetPopup, popup } = useModel();

  const divRef = useRef(null);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      SetPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    if (popup && windowSize.width < 700) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up the effect
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [popup, windowSize]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getprofile();
    getHomePageStories();
    getHomePagePoems();
  }, [form]);

  function getprofile() {
    let array = [];
    getUserByName(form.search).then((response) => {
      array = response.data.data;
      console.log(array);
      console.log(profile._id);
      const index = array.findIndex((obj) => obj._id === profile._id);
      array.splice(index, 1);
      setusers(array);
    });
  }

  function getHomePageStories() {
    let data = {};
    data.search = form.search;
    getStories(data).then((response) => {
      setstory(response.data);
    });
  }

  function getHomePagePoems() {
    let data = {};
    data.search = form.search;
    getPoems(data).then((response) => {
      setpoem(response.data);
    });
  }

  function logout(e) {
    // e.preventDefault()
    // remove local storage
    dispatch({ type: "logout", payload: null });
  }

  useEffect(() => {
    getProfileDetails().then((response) => {
      if (response.status !== statusFailure) {
        setProfile(response.data.data);
        Myprofile(response.data.data);
        profilePic(response.data.data.profilePic);
      } else {
        setError(response.message);
      }
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      if (navbar) {
        if (window.scrollY > 100) {
          navbar.classList.add("navbar-scrolled");
        } else {
          navbar.classList.remove("navbar-scrolled");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function verifyUserAccess(moduleType) {
    if (moduleType === 1) {
      if (profile.isPrime) {
        history.push("/audios");
      } else {
        window.$("#sidebarSubscriptionPlanModal").modal("show");
        setMessageTitle("audio");
      }
    } else if (moduleType === 2) {
      if (profile.isPrime) {
        history.push("/videos");
      } else {
        window.$("#sidebarSubscriptionPlanModal").modal("show");
        setMessageTitle("video");
      }
    } else if (moduleType === 3) {
      if (profile.isPrime) {
        history.push("/movies");
      } else {
        window.$("#sidebarSubscriptionPlanModal").modal("show");
        setMessageTitle("movie");
      }
    }
  }

  function closeModelAndRedirect() {
    window.$("#sidebarSubscriptionPlanModal").modal("hide");
    history.push("/plans");
  }

  function onChangeForm(e) {
    setForm({
      ...form,
      [e.target.getAttribute("name")]: e.target.value,
    });
  }

  function onSubmitForm(e) {
    e.preventDefault();

    switch (form.search_type) {
      case "story":
        window.location.href = `/stories?search=${form.search}`;
        break;
      case "poem":
        window.location.href = `/poetries?search=${form.search}`;
        break;
      case "video":
        window.location.href = `/videos?search=${form.search}`;
        break;
      case "movie":
        window.location.href = `/movies?search=${form.search}`;
        break;
      case "audio":
        window.location.href = `/audios?search=${form.search}`;
        break;
      case "user":
        // window.location.href = `/user/searchUsersByName?name=${form.search}`;
        //   setdrop(true);
        break;
      default:
        break;
    }
    // window.jQuery.modal("hide");
  }

  const HandleClick = (id) => {
    history.push(`/story-details/${id}`);
  };

  const fetchNotificationsCount = async () => {
    try {
      let token = localStorage.getItem("loginDetails");
      let res = {};
      if (token) {
        res = getTokenDetails(token);
      }
      const userId = res._id;

      const response = await getUnreadNotificationCount({ userId });
      const notificationsCount = response.unreadCount || 0;
      setUnreadCount(notificationsCount);
    } catch (error) {
      console.error("Error fetching notification count", error);
      setUnreadCount(0);
    }
  };

  const fetchMessageCount = async () => {
    try {
      let token = localStorage.getItem("loginDetails");
      let res = {};
      if (token) {
        res = getTokenDetails(token);
      }
      const response = await getUnreadMessageCount();
      const messageCount = response.unreadMessagesCount;
      console.log("Unread Message count is:", messageCount);
      setUnreadMessageCount(messageCount);
    } catch (error) {
      console.error("Error getting message count: ", error);
      setUnreadMessageCount(0);
    }
  };
  useEffect(() => {
    fetchMessageCount();
  }, []);
  useEffect(() => {
    fetchNotificationsCount();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexDirection: "column",
        backgroundColor: "#0f1817",
      }}
    >
      <Root>
        <div className="navbarcontainer">
          <Link to={`/`} className="logo">
            <img className="img" src={Logo} alt="logo" />
          </Link>
          <div className="InputContainer">
            {/* <SearchModalComponent></SearchModalComponent> */}
            <div className="input-box">
              <form
                className="form"
                onChange={onChangeForm}
                onSubmit={onSubmitForm}
              >
                <select
                  className="select"
                  name="search_type"
                  value={form.search_type}
                >
                  <option value="story">{t("Nav.Search.Story")}</option>
                  <option value="poem">{t("Nav.Search.Poem")}</option>
                  {/* <option value='audio'>Audio</option>
     <option value='video'>Video</option> */}
                  <option value="user">{t("Nav.Search.User")}</option>
                </select>
                {/* <Search></Search> */}
                <input
                  autoComplete="off"
                  placeholder={t("Nav.Search.InnerSearch")}
                  className="input"
                  name="search"
                  value={form.search}
                ></input>
                {form.search != "" && form.search_type == "user" && (
                  <div className="dropdown">
                    {users.slice(0, 5).map((data) => (
                      <Link
                        style={{ width: "100%" }}
                        to={`/userprofile/${data._id}`}
                      >
                        {data.profilePic && (
                          <div className="profile-search">
                            {data.profilePic === "" ? (
                              <img
                                alt=""
                                className="profile-img"
                                src={Default}
                              ></img>
                            ) : (
                              <img
                                alt=""
                                className="profile-img"
                                src={data.profilePic}
                              ></img>
                            )}
                            <div className="name">
                              <h1 className="span-name">{data.name}</h1>
                              <h1 className="span-subname">{data.name}</h1>
                            </div>
                          </div>
                        )}
                      </Link>
                    ))}
                    {users.length > 0 ? (
                      <div
                        onClick={() =>
                          history.push(
                            `/user/searchUsersByName?name=${form.search}`
                          )
                        }
                        className="view"
                      >
                        {t("Nav.ViewMore")}
                      </div>
                    ) : (
                      <div style={{ color: "white" }}>
                        {" "}
                        {t("Nav.Noresultsfound")}
                      </div>
                    )}
                  </div>
                )}

                {form.search != "" && form.search_type === "story" && (
                  <div className="dropdown">
                    {story.slice(0, 5).map((data) => (
                      <div
                        onClick={() => HandleClick(data._id)}
                        className="profile-search"
                      >
                        <img
                          alt=""
                          className="profile-img"
                          src={data.thumbnail}
                        ></img>
                        <div className="name">
                          <h1 className="span-name">{data.name}</h1>
                          <h1 className="span-subname">{data.authorName}</h1>
                        </div>
                      </div>
                    ))}
                    {story.length > 0 ? (
                      <div
                        onClick={() =>
                          history.push(`/stories?search=${form.search}`)
                        }
                        className="view"
                      >
                        {t("Nav.ViewMore")}
                      </div>
                    ) : (
                      <div style={{ color: "white" }}>
                        {" "}
                        {t("Nav.Noresultsfound")}
                      </div>
                    )}
                  </div>
                )}

                {form.search != "" && form.search_type === "poem" && (
                  <div className="dropdown">
                    {poem.slice(0, 5).map((data) => (
                      <Link
                        style={{ width: "100%" }}
                        to={`/poem-details/${data._id}`}
                      >
                        <div className="profile-search">
                          <img
                            alt=""
                            className="profile-img"
                            src={data.thumbnail}
                          ></img>
                          <div className="name">
                            <h1 className="span-name">{data.name}</h1>
                            <h1 className="span-subname">{data.authorName}</h1>
                          </div>
                        </div>
                      </Link>
                    ))}
                    {poem.length > 0 ? (
                      <div
                        onClick={() =>
                          history.push(`/poetries?search=${form.search}`)
                        }
                        className="view"
                      >
                        {t("Nav.ViewMore")}
                      </div>
                    ) : (
                      <div style={{ color: "white" }}>
                        {" "}
                        {t("Nav.Noresultsfound")}
                      </div>
                    )}
                  </div>
                )}

                <button type="submit" className="button">
                  <Search></Search>
                </button>
              </form>
            </div>
          </div>

          <div className="Sidecontent">
            <div className="left-side">
              <Search
                style={{ width: "21px", height: "21px" }}
                onClick={() => history.push("/search")}
                className="search-icon"
                title="Search"
              />
              {/* <span className='icon-label'>Explore</span> */}
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to={`/explore`}
                title="Explore"
              >
                <div className="icon-container">
                  <Compass className="icon" />
                  <span className="icon-label">{t("Nav.Explore")}</span>
                </div>
              </Link>
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to={`/events`}
                title="Events"
              >
                <div className="icon-container">
                  <Calendar className="icon" />
                  <span className="icon-label">{t("Nav.Events")}</span>
                </div>
              </Link>
              <Link
                style={{ color: "white", textDecoration: "none" }}
                to={`/create`}
                title="Create"
              >
                <div className="icon-container">
                  <Plus className="icon" />
                  <span className="icon-label">{t("Nav.Create")}</span>
                </div>
              </Link>
            </div>

            <hr className="hr" />
            <div className="right-side">
              <Link to={`/chat`} style={{ color: "white" }} title="Message">
                <div className="icon-container-noti">
                  <MessageSquare className="icon" />
                  <span className="icon-label">{t("Nav.Chat")}</span>
                  {unreadMessageCount > 0 && (
                    <span className="badge-chat">{unreadMessageCount}</span>
                  )}
                </div>
              </Link>
              <Link
                to={`/notification`}
                style={{ color: "white" }}
                title="Notification"
              >
                <div className="icon-container-noti">
                  <Bell className="icon" />
                  <span className="icon-label">{t("Nav.notification")}</span>
                  {unreadCount > 0 && (
                    <span className="badge-noti">{unreadCount}</span>
                  )}
                </div>
              </Link>
              <div className="icon-container-profile">
                <div
                  onClick={() => {
                    SetPopup(!popup);
                  }}
                  className="icon-container-profile"
                  title={profile?.name || "Profile"}
                >
                  <img
                    alt="img"
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                    src={profile?.profilePic || Default}
                  />
                  <span className="icon-label-profile">
                    {t("Nav.Profile.Profile")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Root>
      <hr
        style={{
          backgroundColor: "white",
          width: "100%",
          padding: "0",
          marginTop: "7px",
        }}
      ></hr>
      {popup && (
        <PopUp popup={popup} ref={divRef}>
          {windowSize.width > 700 ? (
            <div className="pop-up">
              <Link style={{ color: "white" }} to={`/profile`}>
                <div className="profile-container">
                  {profile.profilePic ? (
                    <img
                      alt="img"
                      onClick={() => {
                        SetPopup(!popup);
                      }}
                      className="profile"
                      src={profile.profilePic}
                    ></img>
                  ) : (
                    <img
                      alt="default"
                      onClick={() => {
                        SetPopup(!popup);
                      }}
                      className="profile"
                      src={Default}
                    ></img>
                  )}

                  <div style={{ marginTop: "5px" }}>
                    {t("Nav.Profile.ViewProfile")}
                  </div>
                </div>
              </Link>
              <Link
                to={`/settings`}
                style={{ color: "white" }}
                className="profile-container"
              >
                <Settings></Settings>
                <div className="text-popup" style={{}}>
                  {t("Nav.Profile.Settings")}
                </div>
              </Link>
              <div
                onClick={() => {
                  logout();
                  SetPopup(false);
                }}
                className="profile-container"
              >
                <LogOut></LogOut>
                <div className="text-popup" style={{}}>
                  {t("Nav.Profile.LogOut")}
                </div>
              </div>
            </div>
          ) : (
            <div className="mobile-popup">
              <Link style={{ color: "white" }} to={`/profile`}>
                <div className="profile-container">
                  <img
                    alt=""
                    onClick={() => {
                      SetPopup(!popup);
                    }}
                    className="profile"
                    src={profile?.profilePic}
                  ></img>

                  <div style={{ marginTop: "5px" }}>
                    {t("Nav.Profile.ViewProfile")}
                  </div>
                </div>
              </Link>
              <Link
                className="profile-container"
                style={{ color: "white" }}
                to={`/settings`}
              >
                <Settings></Settings>
                <div
                  onClick={() => {
                    SetPopup(!popup);
                  }}
                  className="text-popup"
                  style={{}}
                >
                  {t("Nav.Profile.Settings")}
                </div>
              </Link>
              <div
                onClick={() => {
                  logout();
                  SetPopup(false);
                }}
                className="profile-container"
              >
                <LogOut></LogOut>
                <div className="text-popup" style={{}}>
                  {t("Nav.Profile.LogOut")}
                </div>
              </div>
            </div>
          )}
        </PopUp>
      )}
      {/*   
{ search && windowSize.width<700 && 
<SearchPopUp>
    <div className='search-popup'>
    <div className='InputContainer'>  
    <form className='form' onChange={onChangeForm} onSubmit={onSubmitForm}>
    <select  className="select" name='search_type' value={form.search_type} >
      <option value='story'>Story</option>
      <option value='poem'>Poem</option>
     <option value='audio'>Audio</option>
     <option value='video'>Video</option>
     <option value='user'>User</option>
   </select>
  <input 
   autoComplete="off" 
    placeholder='Search...'
   className='input' 
   name='search' value={form.search} ></input> 
   <button type="submit" className="button"><Search></Search></button>
    </form>


    </div> 
     <Plus 
     onClick={()=>setSearch(false)}
     className='plus'></Plus>

     {form.search!='' && form.search_type=="user" && 
  <div className='dropdown'>
        {users.slice(0,5).map((data)=>(
         <Link  style={{width:"100%"}} to={`/userprofile/${data._id}`}>
          {data.profilePic &&  
          <div className='profile-search'>
            {  data.profilePic ==''?
             <img alt=''
             className='profile-img' src={Default}>
            </img> :  
            <img
            alt=''
             className='profile-img' src={data.profilePic}>
            </img> }
            <div className='name'>
              <h1 className='span-name'>{data.name}</h1>
              <h1 className='span-subname'>{data.name}</h1>
            </div>
        </div>}
          </Link>
        ))}
        {users.length>0 ? <div 
        onClick={()=>history.push(`/user/searchUsersByName?name=${form.search}`)}
        className='view'>View More</div>: <div style={{color:"white"}}>No results found</div>}
  </div>

  }

  {form.search!='' && form.search_type==="story" && 
  <div className='dropdown'>
        {story.slice(0,5).map((data)=>(   
          <div 
          onClick={()=>HandleClick(data._id)}
          className='profile-search'>
            <img 
            alt=''
            className='profile-img' src={data.thumbnail}>
            </img> 
            <div className='name'>
              <h1 className='span-name'>{data.name}</h1>
              <h1 className='span-subname'>{data.authorName}</h1>
              <h1 className='span-subname'>{data._id}</h1>
            </div>
        </div>    
        ))}
        {story.length>0 ? 
        <div 
        onClick={()=>history.push(`/stories?search=${form.search}`)}
        className=' view'>View More</div>
        : <div style={{color:"white"}}>No results found</div>
        }
  </div>

  }

  
  {form.search!='' && form.search_type==="poem" && 
  <div className='dropdown'>
        {poem.slice(0,5).map((data)=>(
         <Link  style={{width:"100%"}} to={`/poem-details/${data._id}`}>   
          <div className='profile-search'>
            <img
            alt=''
             className='profile-img' src={data.thumbnail}>
            </img> 
            <div className='name'>
              <h1 className='span-name'>{data.name}</h1>
              <h1 className='span-subname'>{data.authorName}</h1>
            </div>
        </div>
          </Link>
        ))}
        {story.length>0 ? <div 
        onClick={()=>history.push(`/poetries?search=${form.search}`)}
        className=' view'>View More</div>: <div style={{color:"white"}}>No results found</div>
        }
  </div>

  }
    </div>

</SearchPopUp>
} */}
    </div>
  );
};

const SearchPopUp = styled.div`
  width: 100%;
  height: 10px;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .dropdown {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0px;
    border-radius: 20px;
    z-index: 1000;
    background-color: rgba(100, 120, 110, 1);
    top: 60px;
    padding: 15px;
  }
  .profile-search {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    padding: 10px;
  }

  .profile-img {
    border-radius: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .search-popup {
    width: 95%;
    min-height: 50px;
    padding: 20px;
    max-width: 350px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #07262b;
    position: absolute;
    z-index: 1000;
    transition: transform 0.5s ease;
  }

  .select {
    width: 40%;
    min-width: 70px;
    font-size: 15px;
    border: none;
    outline: none;
  }

  .plus {
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(45deg);
  }

  .form {
    display: flex;
  }

  .InputContainer {
    display: flex;
    width: 100%;
    min-width: 300px;
    max-width: 300px;
    padding-right: 10px;
    height: 30px;
    overflow: hidden;
    border-radius: 10px;
    background-color: white;
  }

  .button {
    outline: none;
    border: none;
    background-color: white;
  }

  .name {
    margin-top: 5px;
    font-weight: bold;
  }

  .span-name {
    font-size: 10px;
    font-family: Arial;
  }

  .span-subname {
    margin-top: -5px;
    font-size: 8px;
    font-family: sans-serif;
  }

  .input {
    padding: 2px;
    outline: none;
    widows: 70%;
    display: block;
    border: none;
    padding-left: 10px;
  }
`;

const PopUp = styled.div`
  @media screen and (min-width: 701px) {
    max-width: 1400px;
    width: 100%;
    height: 20px;
    position: relative;
  }

  .text-popup {
    margin-left: 8px;
  }

  .mobile-popup {
    width: 100%;
    height: 180px;
    padding: 20px;
    color: white;
    background-color: #07262b;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    z-index: 1000;
    bottom: 0;
    left: 0;
    transition: transform 0.5s ease;
  }

  .pop-up {
    width: 200px;
    height: 150px;
    background-color: #07262b;
    border-radius: 5px;
    color: white;
    position: absolute;
    right: 15px;
    top: -30px;
    z-index: 1000;
    padding: 10px;
    padding-left: 20px;
  }
  .profile-container {
    display: flex;
    gap: 10px;
    margin-left: 10px;
    cursor: pointer;
    padding-bottom: 20px;
  }

  .profile-container-img {
    display: flex;
    gap: 10px;
    // margin-bottom:20px;
  }
  .profile {
    width: 30px;
    height: 30px;
    border-radius: 50px;
  }
`;
const Root = styled.div`
  width: 100%;
  background-color: #0f1817;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .profile-img {
    border-radius: 20px;
    width: 45px;
    height: 45px;
    cursor: pointer;
  }

  .name {
    margin-top: 5px;
    font-weight: bold;
  }

  .view {
    ${"" /* color:#263a38; */}
    color:white;
    font-weight: bold;
    cursor: pointer;
    font-size: 10px;
    margin-left: 10px;
  }

  .profile-search {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    padding: 10px;
  }

  .profile-search:hover {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    border-radius: 20px;
    background-color: #263a38;
  }
  .Sidecontent {
    width: 100%;
  }

  .span-name {
    font-size: 10px;
    font-family: Arial;
  }

  .span-subname {
    margin-top: -5px;
    font-size: 8px;
    font-family: sans-serif;
  }

  .button {
    outline: none;
    border: none;
    background-color: #263a38;
    color: white;
    margin-top: -3px;
  }

  .form {
    display: flex;
    width: 100%;
  }

  .icon-label {
    font-size: 10px;
    // margin-bottom:px;
    // margin-right:5px;
  }
  .icon-label-profile {
    font-size: 10px;
    // padding-bottom:10px;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    margin-top: 10px;
  }
  .icon-container-noti {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    margin-top: 10px;
    position: relative; /* Ensure that the badge is positioned relative to this container */
  }
  .icon-container-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    padding-top: 0px;
  }

  /* Hide text on mobile screens and adjust margin */
  @media (max-width: 600px) {
    .icon-label {
      display: none;
    }
    .icon-label-profile {
      display: none;
    }

    .icon-container {
      margin-top: 0px;
    }
    .icon-container-profile {
      width: 29px;
      height: 31px;
      margin-top: 0px;
    }
    .icon-container-noti {
      margin-top: 0px;
    }
  }

  .dropdown {
    position: absolute;
    width: 100%;
    height: auto;
    left: 0px;
    border-radius: 20px;
    z-index: 1000;
    background-color: rgba(100, 120, 110, 1);
    top: 50px;
    padding: 15px;
  }

  .select-hr {
    width: 25px;
    border: none;
    border: 1px solid black;
    margin-bottom: 20px;
    transform: rotate(90deg);
  }

  .select {
    background-color: #263a38;
    outline: none;
    border: none;
    color: white;
  }

  .search-icon {
    display: block;
  }

  @media screen and (min-width: 600px) {
    .search-icon {
      display: none;
    }
  }

  .navbarcontainer {
    padding-top: 10px;
    display: flex;
    height: 60px;
    width: 94%;
    max-width: 1300px;
    justify-content: space-between;
    align-items: center;
  }

  .left-side {
    color: white;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 13%;
    max-width: 100px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (min-width: 701px) and (max-width: 1800px) {
      grid-template-columns: repeat(3, 1fr);
      margin-right: -20px;
    }
    @media  screen and (min-width: 350px) and (max-width: 400px){
    gap:8%;
    }
  }

  .profile-nav {
    border-radius: 50px;
    //  margin-bottom:10px;
    width: 25px;
    height: 25px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
  .badge-noti {
    position: absolute;
    bottom: 16px;
    right: 10px;
    background-color: #962727;
    color: white;
    border-radius: 50%;
    padding: 1px 3px;
    font-size: 7px;
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 12px;
    min-height: 12px;
    aspect-ratio: 1/1;

    @media (max-width: 600px) {
      bottom: -4px;
      right: -5px;
      font-size: 7px;
    }
  }

  .badge-chat {
    position: absolute;
    bottom: 16px;
    right: -4px;
    background-color: #962727;
    color: white;
    border-radius: 50%;
    padding: 1px 3px;
    font-size: 7px;
    line-height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 12px;
    min-height: 12px;
    aspect-ratio: 1/1;

    @media (max-width: 600px) {
      bottom: -4px;
      right: -5px;
      font-size: 7px;
    }
  }
  .right-side {
    color: white;
    align-items: center;
    justify-content: center;
    width: 100%;

    max-width: 100px;
    gap: 13%;
    padding-right: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .right-side {
  }
  .hr {
    width: 25px;
    border: none;
    border-top: 1px solid white; /* Set the border color */
    transform: rotate(90deg);
  }

  .Sidecontent {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 280px;
    width: 100%;
    height: 100%;
  }

  .input {
    outline: none;
    border: none;
    width: 100%;
    height: 80%;
    display: block;
    margin-top: 3px;
    border-radius: 10px;
    background-color: #263a38;
    padding-left: 10px;
    color: white;
  }

  .logo {
    padding: 5px;
    gap: 15%;
    margin-left: 20px;
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    max-width: 100px;
  }
  .img {
    width: 40px;
    height: 40px;

    background: radial-gradient(
      50.01% 50% at 50% 50%,
      #ffffff 0%,
      rgba(217, 217, 217, 0) 100%
    );
  }

  .input-box {
    display: flex;
    width: 100%;
    height: 80%;
    padding: 5px;
    background-color: #263a38;
    border-radius: 10px;
  }

  .InputContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 100%;
    height: 100%;
    flex-basis: 2/5;
    max-width: 500px;
    margin-right: 20px;
    @media screen and (min-width: 350px) and (max-width: 700px) {
      display: none;
    }
  }
`;

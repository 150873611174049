import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Import slider library if using react-slick or a similar library
import styled from "styled-components";
import { useHistory } from 'react-router-dom'; // For navigation
import { getbannerResult } from '../views/utilities/ApiCalls'; // Assuming this fetches banner data // Utility to parse token details
import axios from 'axios';
import Constant from '../views/Constant'; // Adjust path as per your project structure
import Layout from "../Layout/Layout";

import { t } from 'i18next'; // Assuming i18next is used for translations
import { getTokenDetails } from '../views/utilities/CommonFunction';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';

export const CustomCarousel = () => {
  const [bannerData, setBannerData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const history = useHistory();

  const getbanner = async () => {
    try {
      const res = await getbannerResult();
      console.log("Banner details", res.data);
      setBannerData(res.data);
    } catch (error) {
      console.log("Error in fetching banner details", error);
    }
  };
  useEffect(() => {
    getbanner();
  }, []);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return format(date, "do MMM yyyy 'at' hh.mm aaaa ('IST')", {
      locale: enUS,
    });
  };

  const handleContestClick = async (language, contestId, contestName, contestType) => {
    const contest = bannerData.find((c) => c.eventId._id === contestId);
    if (contest) {
      const startDateTime = new Date(`${contest.eventId.startDate}T${contest.eventId.startTime}`);
      const closureDateTime = new Date(`${contest.eventId.closureDate}T${contest.eventId.closureTime}`);
      const resultDateTime = new Date(`${contest.eventId.resultDate}T${contest.eventId.resultTime}`);
      const currentDateTime = new Date();
      const userLanguage = language || "English";

      if (currentDateTime >= closureDateTime && currentDateTime <= resultDateTime) {
        history.push({
          pathname: contestType === "story" ? `/contestStories/${contestId}` : `/contestPoetries/${contestId}`,
          search: `?resultDate=${resultDateTime}`,
        });
      } else if (currentDateTime >= startDateTime && currentDateTime <= closureDateTime) {
        const token = localStorage.getItem("loginDetails");
        const userDetails = token ? getTokenDetails(token) : {};

        try {
          const endpoint = contestType === "story" ? "story/filter" : "poem/filter";
          const response = await axios.get(
            `${Constant.apiBasePath}${endpoint}?contestId=${contestId}`
          );
          const stories = response.data;
          const userHasSubmitted = stories.some((story) => story.userId === userDetails._id);

          if (userHasSubmitted) {
            setErrorMessage("Story already submitted");
            setTimeout(() => setErrorMessage(""), 3000);
          } else {
            history.push({
              pathname: contestType === "story"
                ? `/contest-story`
                : `/contest-poem`,
              search: `?contestId=${contestId}&language=${userLanguage}`,
            });
          }
          // history.push({
          //   pathname: contestType === "story"
          //     ? `/contest-story`
          //     : `/contest-poem`,
          //   search: `?contestId=${contestId}&language=${userLanguage}`,
          // });
        } catch (error) {
          console.error("Error fetching stories:", error);
        }
      } else if (currentDateTime > resultDateTime) {
        history.push({
          pathname: `/contest-result/${contestId}/${contestType}`,
          state: { contestName },
        });
      }
    }
  };

  const handleOpenMicClick = (id, name)=>{
    history.push(`/openmic/${id}`);
  }

  const handleAfterChange = (index) => {
    setCurrentSlide(index); // Update index for the combined slider
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
  };

  return (
      <Container>
      <Section>
        <Heading>{t("Events.Events")}</Heading>
        {errorMessage && <Error>{errorMessage}</Error>}
        <SliderContainer>
          <Slider {...settings} afterChange={handleAfterChange}>
            {bannerData.map((item, index) => {
              const currentDateTime = new Date();
              const isContest = item.type === "contest";
              const startDateTime = isContest ? new Date(`${item.eventId.startDate}T${item.eventId.startTime}`) : null;
              const closureDateTime = isContest ? new Date(`${item.eventId.closureDate}T${item.eventId.closureTime}`) : null;
              const resultDateTime = isContest ? new Date(`${item.eventId.resultDate}T${item.eventId.resultTime}`) : null;

              const handleClick = () => {
                if (isContest) {
                  handleContestClick(
                    item.eventId.language,
                    item.eventId._id,
                    item.eventId.name,
                    item.eventId.type
                  );
                } else {
                  handleOpenMicClick(item.eventId, item.name);
                }
              };

              return (
                <SliderItem key={item._id} onClick={handleClick}>
                  <Image
                    src={item.image || "https://images.unsplash.com/photo-1485579149621-3123dd979885?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
                    alt={item.name}
                  />
                  {
                    isContest ? (
                        <Title>{item.name}</Title>
                    ):(
                        <OpenMicTitle>{item.name}</OpenMicTitle>
                    )
                  }
                  {isContest && (
                    <Subtitle>
                      {currentDateTime >= startDateTime && currentDateTime <= closureDateTime ? (
                        <span>{t("Events.ContestIsLive")}</span>
                      ) : currentDateTime > closureDateTime && currentDateTime < resultDateTime ? (
                        <span>{t("Events.VotingPeriod")}</span>
                      ) : currentDateTime < startDateTime ? (
                        <span>
                          {t("Events.ContestStartOn")}{" "}
                          {formatDateTime(`${item.eventId.startDate} ${item.eventId.startTime}`)}
                        </span>
                      ) : (
                        <span>{t("Events.ContestEnd")}</span>
                      )}
                    </Subtitle>
                  ) }
                </SliderItem>
              );
            })}
          </Slider>
        </SliderContainer>
      </Section>
      </Container>
  );
};




const SliderItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
  width: 100%; /* Ensure it takes full width */
  height: 280px; /* Fixed height for larger screens */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    height: 280px; /* Adjust height for tablets */
  }

  @media (max-width: 480px) {
    height: 190px; /* Adjust height for mobile devices */
    width: 100%; /* Ensure the width is full on mobile */
    border-radius: 8px; /* Smaller border radius for mobile */
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  &.active {
    transform: scale(1.05);
    z-index: 1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  &:hover {
    opacity: 0.9;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 12px;

  @media (max-width: 768px) {
    height: 200px;
    width: 100%;
  }
`;

const Title = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 1em; /* Adjust font size for smaller screens */
    left: 10px; /* Adjust position for smaller screens */
    bottom: 50px; /* Ensure it doesn't overlap with the subtitle */
  }
`;
const OpenMicTitle = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 1em; /* Adjust font size for smaller screens */
    left: 10px; /* Adjust position for smaller screens */
  }
`;

const Subtitle = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-size: 0.9em;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 0.8em; /* Adjust font size for smaller screens */
    left: 10px; /* Adjust position for smaller screens */
    bottom: 10px; /* Ensure it fits well with the title */
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  background: #0f1817;
  box-sizing: border-box;
`;

const Section = styled.section`
  margin-bottom: 60px;
`;

const Heading = styled.h1`
  font-size: 2.2em;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
`;

const Error = styled.div`
  color: red;
  text-align: center;
  font-size: 1.3em;
  margin: 40px 0;
`;

const SliderContainer = styled.div`
  width: 78%; /* Default width for larger screens */
  height: 50%;
  margin: 0 auto;

  @media (max-width: 1189px) {
    width: 92%; /* Adjust width for tablets if needed */
  }
  @media (max-width: 768px) {
    width: 92%; /* Adjust width for tablets if needed */
  }

  @media (max-width: 480px) {
    width: 92%; /* Adjust width for mobile devices */
  }
`;


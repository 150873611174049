import Axios from "axios";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import { useContext, useEffect, useState } from "react";
import { registerPlugin } from "react-filepond";
import { useHistory } from "react-router";
import Context from "../Context";
import Layout from "../Layout/Layout";
import Constant, { statusFailure } from "./Constant";
import {
  DeleteStory,
  createPaymentRequest,
  createStory,
  getDataById,
  getStoryCategories,
  getThumbnailsFromAI,
  uploadThumbImage,
  getStoryCategoriesCopy,
  getLanguages,
} from "./utilities/ApiCalls";
import imageCompression from "browser-image-compression";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import $, { param } from "jquery";
import { useTranslation } from "react-i18next";
import { getTokenDetails } from "./utilities/CommonFunction";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Check, Cross, Loader, Loader2, Plus } from "lucide-react";
import { Cropper } from "react-cropper";
let val = 1;
let arr = [];

// register if want to preview

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function StoryWrite(props) {
  const location = useLocation();
  // const constest_id = location.state?.contestId;
  const query = new URLSearchParams(props.location.search);
  let constest_id = query.get("contestId");
  let language = query.get("language");
  let id = query.get("userId");
  let type = "story";

  const { t } = useTranslation();
  let amount = query.get("amount");

  let token = localStorage.getItem("loginDetails");
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");
  const [documents, setDocuments] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [genBtnStatus, setgenBtnStatus] = useState(false);
  const [imgdata, setImgData] = useState("");
  const [thumblain, setThumblain] = useState(null);
  const { state, dispatch } = useContext(Context);
  const [getImage, setGetImage] = useState(false);
  const [CloseSuccess, setCloseSuccess] = useState(false);
  const [closeError, setCloseError] = useState(false);
  const [storyForm, setStoryForm] = useState({
    name: "",
    thumbnail: "",
    categoryId: [],
    description: "",
    document: [],
    duration: "",
    contestId: constest_id,
    languageId: "",
    id: "",
    isAdultContent: false,
  });

  const [base64Image, setBase64Image] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [btnStatus, setbtnStatus] = useState(false);
  const [images, setImages] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [length, setLength] = useState(1);
  const [retry, setRetry] = useState(0);
  const [contentData, setContentData] = useState({});
  const [image, setImage] = useState(null);
  const [cropData, setCropData] = useState(null);
  const [cropper, setCropper] = useState(null);

  useEffect(() => {
    // hide second step
    $("#second-step").hide();
    let prams = {};
    if (constest_id) prams.contestType = 1;

    if (constest_id) {
      setStoryForm({
        ...storyForm,
        contestId: constest_id,
      });
    }

    // let prams = { moduleType: 1 };
    getStoryCategories(prams).then((response) => {
      if (response.status !== statusFailure) {
        console.log("response.data-------------->");
        console.log(response);
        setCategories(response.data);
      } else {
        setError(response.message);
        setCloseError(false);
      }
    });
    getLanguages().then((response) => {
      if (response.status !== statusFailure) {
        console.log(response);
        setLanguages(response);
        setStoryForm({ ...storyForm, languageId: response[0]._id });
      } else {
        setError(response.message);
        setCloseError(false);
      }
    });

    getTHumbnail(1);

    if (id && type) {
      prams = { id: id, type: type };
      getDataById(prams).then((data) => {
        if (data.status === Constant.statusSuccess) {
          setContentData(data.data[0]);

          // make category active

          if (data.data[0].categoryId.length > 0) {
            let categoryIds = data.data[0].categoryId;
            categoryIds.map((el) => {
              $("#" + el).addClass("active");
            });
          }

          setStoryForm({
            ...storyForm,
            id: id,
            name: data.data[0].name,
            description: data.data[0].description,
            thumbnail: data.data[0].thumbnail,
            duration: data.data[0].duration,
            categoryId: data.data[0].categoryId,
            languageId: data.data[0].languageId,
            contestId: data.data[0].contestId,
            isAdultContent: data.data[0].isAdultContent,
          });
        } else {
          setError(data.message);
          setCloseError(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    // let prams = {};
    // if (constest_id) prams.contestType = 1;
    let prams = {};
    if (constest_id) prams.contestType = 1;

    getStoryCategories(prams).then((response) => {
      if (response.status !== statusFailure) {
        console.log("response category----------------->");
        console.log(response);
        setCategories(response.data);
      } else {
        setError(response.message);
        setCloseError(false);
      }
    });
    getLanguages().then((response) => {
      if (response.status !== statusFailure) {
        console.log("response category----------------->");
        console.log(response);
        setLanguages(response);
        setStoryForm({ ...storyForm, languageId: response[0]._id });
      } else {
        setError(response.message);
        setCloseError(false);
      }
    });
  }, []);

  useEffect(() => {
    console.log("stated changed", storyForm);
  }, [storyForm]);

  function onFormChange(e) {
    if (e.target.id === "isAdultContent") {
      console.log(e.target.checked);
      setStoryForm({
        ...storyForm,
        isAdultContent: e.target.checked,
      });
    } else {
      if (e.target.id === "name") {
        let words = e.target.value.split(" ").length;
        if (words > 50) {
          setError("Title should be less then or equal to 5 words.");
          setCloseError(false);
          setbtnStatus(true);
        } else {
          setError("");
          setbtnStatus(false);
        }
      }

      if (e.target.id === "description") {
        let words = e.target.value.split(" ").length;
        // if (words > 5) {
        //   setError("Description should be less then or equal to 5 words.");
        //   setbtnStatus(true);
        // } else {
        //   setError("");
        //   setbtnStatus(false);
        // }
        console.log("contest id");
      }

      setStoryForm({
        ...storyForm,
        [e.target.name]: e.target.value,
      });
    }
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        // Define compression options
        const options = {
          maxSizeMB: 2, // Target size in MB
          maxWidthOrHeight: 1024, // Max width or height of the image
          useWebWorker: true, // Use multi-threading for compression
        };

        // Compress the image file
        const compressedFile = await imageCompression(file, options);
        console.log("compressed file", compressedFile);

        // Convert the compressed file to base64
        convertToBase64(compressedFile);

        // Store the compressed file
        setSelectedFile(compressedFile);
      } catch (error) {
        console.error("Error compressing file:", error);
      }
    }

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files[0] instanceof Blob) {
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result);
      };

      reader.readAsDataURL(files[0]);
    } else {
      console.error("The provided file is not a valid Blob or File.");
    }
  };

  const getCropData = async () => {
    console.log("getCropData", cropper);
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas({
        width: 1024, // Resize as needed
        height: 1024,
      });
  
      const base64Data = croppedCanvas.toDataURL("image/jpeg", 0.8); // Compress on canvas
      const blobData = await fetch(base64Data).then((res) => res.blob());
  
      try {
        const options = {
          maxSizeMB: 2, // Target size in MB
          maxWidthOrHeight: 1024, // Max width or height of the image
          useWebWorker: true, // Use multi-threading for compression
        };
  
        const compressedBlob = await imageCompression(blobData, options);
        const compressedBase64 = await convertToBase64(compressedBlob);
  
        setBase64Image(compressedBase64);
        const response = await uploadThumbImage(compressedBase64); // Upload the compressed cropped image
        let form = storyForm;
      form["thumbnail"] = response.location;

      } catch (error) {
        console.error("Error compressing cropped image:", error);
      }
    }
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBase64Image(reader.result);
      setImgData(reader.result);
    };
  };

  function updateLanguage(value) {
    setStoryForm({
      ...storyForm,
      languageId: value,
    });
  }
  function updateCategory(id) {
    // e.preventDefault()
    if ($("#" + id).hasClass("active")) {
      val -= 1;
      $("#" + id).removeClass("active");
      $("#" + id + "_check").removeClass("icofont-plus");
      $("#" + id + "_check").addClass("icofont-check");
      var index = arr
        .map((x) => {
          return x;
        })
        .indexOf(id);
      arr.splice(index, 1);
    } else {
      val += 1;
      $("#" + id).addClass("active");
      $("#" + id + "_check").removeClass("icofont-check");
      $("#" + id + "_check").addClass("icofont-plus");
      arr.push(id);
    }

    setStoryForm({
      ...storyForm,
      categoryId: arr,
    });
  }

  async function getTHumbnail(page) {
    let token = localStorage.getItem("loginDetails");
    let response = await Axios.get(
      Constant.apiBasePath + "content-thumbnail/getDataForUser?pageNo=" + page,
      {
        headers: { token: token },
      }
    );
    console.log("asassaa", response.data);
    if (response.status !== "Success") {
      console.log("asassaa");
      setImages(response.data.data);
      setPageNo(response.data.pageNo);
      setLength(response.data.length);
    } else {
      setError(response.message);
      setCloseError(false);
    }
  }

  function setThumbnail(thumbnail, id) {
    setStoryForm({
      ...storyForm,
      thumbnail: thumbnail,
    });

    $(".ticcircle").removeClass("active");
    $("#ticcircle_" + id).addClass("active");
  }

  async function submitStory(e) {
    console.log("Logging story form", storyForm);
    // return
    e.preventDefault();
    setbtnStatus(true);

    if (selectedFile) {
      // Ensure image is converted to base64
      await convertToBase64(selectedFile);
    }

    let form = { ...storyForm };
    if (imgdata !== "") {
      let response = await uploadThumbImage(imgdata);

      form = storyForm;
      form["thumbnail"] = response.location;
    } else {
      form = storyForm;
    }
    createStory(form).then((response) => {
      if (response.status !== statusFailure && response.data != "") {
        let token = localStorage.getItem("loginDetails");
        let userDetails = {};
        if (token) {
          userDetails = getTokenDetails(token);
        }
        // call api for create payment request

        if (storyForm.contestId && amount) {
          let paymentRequest = {
            amount: parseInt(amount),
            purpose: "Write story",
            redirectURL: `${Constant.domain}` + `profile/` + userDetails._id,
            isPrime: 1,
            paymentFor: 2,
            enrollmentId: response.data._id,
          };
          createPaymentRequest(paymentRequest)
            .then((orderResponse) => {
              if (orderResponse.status === Constant.statusSuccess) {
                window.location.href = orderResponse.data.longurl;
              } else {
                setError(orderResponse.message);
                setCloseError(false);
              }
            })
            .catch((error) => {
              console.log(error.response.data.message);
            });
        } else {
          if (id && type) {
            setSuccessMessage(response.message);
            setCloseSuccess(false);
            if (userDetails) {
              setSuccessMessage(response.message);
              setCloseSuccess(false);
              setTimeout(function () {
                history.push(`/profile/${userDetails._id}`);
              }, 3000);
            }
          } else {
            if (response.isAccess === false) {
              setSuccessMessage(response.message);
              setCloseSuccess(false);
              setTimeout(function () {
                history.push("/plans");
              }, 3000);
            } else {
              setSuccessMessage(response.message);
              setCloseSuccess(false);
              setTimeout(function () {
                history.push("/");
              }, 3000);
            }
          }
        }
      } else {
        setbtnStatus(false);
        setError(response.message);
        setCloseError(false);
      }
    });
  }

  function uploadDocuments(response) {
    response = JSON.parse(response);
    if (response.status !== statusFailure) {
      setStoryForm({
        ...storyForm,
        document: [...storyForm.document, response.location],
      });
    } else {
      setError(response.message);
      setCloseError(false);
    }
  }

  function previousPage(page) {
    setPageNo(page);
  }

  function nextPage(page) {
    setPageNo(page);
  }

  var previous = 0;
  var next = 0;
  var customLength = 0;

  if (pageNo >= 0) {
    previous = pageNo - 1;
    next = pageNo + 1;
  }

  if (length !== 0) {
    customLength = length;
  }

  function handleModel(type) {
    if (type === 1) {
      window.$("#contentThumbnail").modal("show");
    } else {
      window.$("#contentThumbnail").modal("hide");
    }
  }

  function uploadThumbnail() {
    document.getElementById("file-image").classList.remove("hidden");
    document.getElementById("file-image").src =
      "https://story-tent-testing.s3.ap-south-1.amazonaws.com/contest/image_1706224293881.jpg";
    window.$("#contentThumbnail").modal("hide");
  }

  if (contentData.name !== undefined) {
    document.getElementById("file-image").classList.remove("hidden");
    document.getElementById("file-image").src = contentData.thumbnail;
  }
  async function getAIgeneratedImage(e) {
    console.log(storyForm.name);
    console.log(storyForm.description);
    if (retry < 3) {
      e.preventDefault();
      setgenBtnStatus(true);
      var data = await getThumbnailsFromAI(
        storyForm.name
        // storyForm.description
      );
      setImgData(data[1]);
      setRetry(retry + 1);
      console.log(retry);
      document.getElementById("file-image").classList.remove("hidden");
      document.getElementById("file-image").src = data[0];
      e.preventDefault();
      setgenBtnStatus(false);
      setGetImage(true);
    } else {
      setError(
        "You have exceeded AI Thumbnail generation limit which is 3 images"
      );
      setCloseError(false);
    }
  }
  function validatePreviewData() {
    if (storyForm.name === "") {
      setbtnStatus(true);
      setError('"name" is not allowed to be empty');
      setCloseError(false);
    } else if (storyForm.thumbnail === "") {
      setbtnStatus(true);
      setError('"thumbnail" is not allowed to be empty');
      setCloseError(false);
      // storyForm.thumbnail= "https://story-tent-testing.s3.ap-south-1.amazonaws.com/contest/image_1706224293881.jpg"
    } else if (storyForm.categoryId.length < 1) {
      setbtnStatus(true);
      setError('"categoryId" does not contain 1 required value(s)');
      setCloseError(false);
    } else if (storyForm.duration === "") {
      setbtnStatus(true);
      setError('"duration" is not allowed to be empty');
      setCloseError(false);
    } else if (storyForm.languageId === "") {
      setbtnStatus(true);
      setError("Please Select a language");
      setCloseError(false);
    } else {
      setError("");
      setbtnStatus(false);
      $("#first-step").hide();
      $("#second-step").show();
    }
  }

  function hideStepTwo() {
    $("#second-step").hide();
    $("#first-step").show();
  }

  const getTitleText = () => {
    if (language === "Hindi") {
      return "कहानी का शीर्षक दर्ज करें";
    } else {
      return "Enter Story title";
    }
  };
  const getCategoryText = () => {
    if (language === "Category") {
      return "वर्ग";
    } else {
      return "Category";
    }
  };
  const getLanguageText = () => {
    if (language === "Language") {
      return "वर्ग";
    } else {
      return "Language";
    }
  };
  const getDurationText = () => {
    if (language === "Hindi") {
      return "अवधि";
    } else {
      return "Duration";
    }
  };
  const getWriteStoryText = () => {
    if (language === "Hindi") {
      return "कहानी लिखें";
    } else {
      return "Write Story";
    }
  };
  const getGenerateButtonText = () => {
    if (language === "Hindi") {
      return "AI के साथ जनरेट करें";
    } else {
      return "Generate With AI";
    }
  };

  const getSubmitButtonText = () => {
    if (language === "Hindi") {
      return "सबमिट करे ";
    } else {
      return "Submit To Publish";
    }
  };
  const getUploadText = () => {
    if (language === "Hindi") {
      return "कहानी शीर्षक छवि अपलोड करें";
    } else {
      return "Upload Story Title Image";
    }
  };

  return (
    <Layout>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          backgroundColor: "#0f1817",
          height: "auto",
        }}
      >
        <Root>
          {error && !closeError && (
            <div className="pop-up" style={{zIndex:99}}>
              <div
                onClick={() => setCloseError(true)}
                style={{
                  position: "absolute",
                  color: "black",
                  top: "0px",
                  right: "0px",
                }}
              >
                <Plus className="cross"></Plus>
              </div>
              <div>
                {" "}
                <span
                  style={{
                    backgroundColor: "red",
                    padding: "2px",
                    borderRadius: "50px",
                  }}
                >
                  <Plus className="cross"></Plus>
                </span>{" "}
                {error}
              </div>
            </div>
          )}

          {successMessage && !CloseSuccess && (
            <div className="pop-up-success" style={{zIndex:99}}>
              <div
                onClick={() => setCloseSuccess(true)}
                style={{
                  position: "absolute",
                  color: "white",
                  top: "0px",
                  right: "0px",
                }}
              >
                <Plus className="cross"></Plus>
              </div>{" "}
              <span
                style={{
                  backgroundColor: "green",
                  padding: "2px",
                  borderRadius: "50px",
                  marginRight: "10px",
                }}
              >
                <Check style={{ color: "white" }}></Check>
              </span>{" "}
              {successMessage}{" "}
            </div>
          )}

          <div className=" create-story-container ">
            <div className="input-container">
              <h1 className="label">{t("Write.writeStory.EnterTitle")}</h1>
              <input
                onChange={onFormChange}
                name="name"
                id="name"
                type="text"
                defaultValue={storyForm.name}
                required
                placeholder={t("Write.writeStory.innerTitle")}
                className="input"
              ></input>
            </div>

            <div className="input-container-select">
              <h1 className="label">
                {t("Write.writeStory.Category.Category")}
              </h1>
              <select
                onChange={(e) => {
                  updateCategory(e.target.value);
                }}
                name="category"
                className="input-select"
              >
                <option value="category" selected>
                  {" "}
                  {t("Write.writeStory.Category.Category")}
                </option>
                {categories &&
                  categories.length > 0 &&
                  categories.map((category, index) => {
                    return (
                      <option key={category._id} value={category._id}>
                        {" "}
                        {category.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="input-container-duration">
              <h1 className="label">{t("Write.writeStory.Duration")}</h1>
              <input
                placeholder={t("Write.writeStory.innerDuration")}
                onChange={onFormChange}
                name="duration"
                type="Number"
                defaultValue={storyForm.duration}
                required
                className="input"
              ></input>
            </div>
            <div className="input-container-select-lang">
              <h1 className="label">
                {t("Write.WritePoem.Language.Language")}
              </h1>
              <select
                onChange={(e) => {
                  updateLanguage(e.target.value);
                }}
                name="languageId"
                className="input-select"
              >
                {languages &&
                  languages.length > 0 &&
                  languages.map((language, index) => {
                    return (
                      <option key={language.label} value={language._id}>
                        {language.label}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="story-write-container">
            <div className="input-container-story-write">
              <h1>{t("Write.writeStory.WriteStory")}</h1>
              <textarea
                rows={20}
                type="text"
                className="story-writing-area"
                placeholder={t("Write.writeStory.innerWriteStory")}
                onChange={onFormChange}
                name="description"
                id="description"
                defaultValue={storyForm.description}
              />
            </div>
            <div className="uploader-outer-container">
            <div className="uploader-container">
                <form id="file-upload-form" className="uploader">
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    name="thumbnail"
                    style={{ display: "none" }}
                  />
                  <label htmlFor="file-upload" id="file-drag">
                    <div id="image-container">
                      {image ? (
                        <Cropper
                          style={{
                            height: 200,
                            width: "100%",
                            marginBottom: "10px",
                          }}
                          zoomTo={0.5}
                          initialAspectRatio={1}
                          preview=".img-preview"
                          src={image}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          background={false}
                          responsive={true}
                          autoCropArea={1}
                          checkOrientation={false}
                          onInitialized={(instance) => {
                            setCropper(instance);
                          }}
                          guides={true}
                        />
                      ) : (
                        <img
                          id="file-image"
                          src={base64Image || "/images/download-image.png"}
                          alt="Preview"
                          className={base64Image ? "" : "hidden"}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "contain",
                            display: "block",
                          }}
                        />
                      )}
                    </div>
                    <div id="text-container">
                      <div id="start" className={base64Image ? "hidden" : ""}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                        <div>
                          <div style={{ color: "white", fontSize: "15px" }}>
                            {t("selected image is shown here")}
                          </div>
                        </div>
                        <div id="notimage" className="hidden">
                          Please select an image
                        </div>
                      </div>
                    </div>
                    <div id="response" className="hidden">
                      <div id="messages"></div>
                      <progress
                        className="progress"
                        id="file-progress"
                        value="0"
                      >
                        <span>100%</span>%
                      </progress>
                    </div>
                  </label>
                </form>
              </div>

              {image && (
                <div
                  className="cropper-buttons"
                  style={{ marginBottom: "10px",marginTop: "10px"  }}
                >
                  <button
                    type="button"
                    onClick={getCropData}
                    className="button"
                  >
                    Crop Image
                  </button>
                </div>
              )}

              <div className="uploader-buttons">
                <button
                  type="button"
                  onClick={() => document.getElementById("file-upload").click()}
                  className="button"
                >
                  <span>{t("choose image")}</span>
                </button>
                <button
                  type="button"
                  onClick={submitStory}
                  disabled={btnStatus}
                  className="button"
                >
                  {btnStatus ? (
                    <Loader2 className="loader"></Loader2>
                  ) : (
                    <span>{t("Write.writeStory.Button2")}</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Root>
      </div>
    </Layout>
  );
}

export default StoryWrite;

const Root = styled.div`
  min-height: 100vh;
  max-width: 1300px;
  height: auto;
  width: 100%;
  background-color: #0f1817;

  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .uploader-buttons {
    margin-top: 10px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }
  .loader {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .pop-up-success {
    width: 80%;
    min-width: 300px;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    min-height: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    color: white;
    background-color: #263a38;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-left: 3px solid green; /* Add red border at the bottom */
  }

  .pop-up {
    width: 100%;
    min-width: 300px;
    padding-left: 10px;
    padding-right: 30px;
    width: auto;
    min-height: 40px;
    height: auto;
    color: white;
    background-color: #263a38;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-left: 3px solid red; /* Add red border at the bottom */
  }

  .cross {
    transform: rotate(45deg);
    color: white;
  }

  .button {
    border: none;
    outline: none;
    padding: 5px;
    background-color: #c81919;
    color: white;
    border-radius: 20px;
    width: 175px;
  }

  .uploader-outer-container {
    width: 30%;
    max-width: 350px;
    @media (min-width: 350px) and (max-width: 900px) {
      width: 80%;
      min-width: 300px;
      margin-top: 20px;
    }
  }

  .create-story-container {
    width: 90%;
    display: flex;
    gap: 20px;
    @media (min-width: 350px) and (max-width: 900px) {
      flex-direction: column;
      gap: 20px;
      width: 80%;
    }
  }

  .input-container-story-write {
    width: 70%;
    @media (min-width: 350px) and (max-width: 900px) {
      width: 100%;
    }
  }

  .uploader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .story-writing-area {
    width: 100%;
    background-color: #0c343a;
    border-radius: 10px;
    outline: none;
    border: none;
    padding-left: 20px;
    color: white;
  }

  .story-write-container {
    margin-top: 20px;
    width: 90%;
    gap: 20px;
    display: flex;
    @media (min-width: 350px) and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;
    }
  }

  .uploader-container {
    width: 100%;
    max-width: 350px;
    border: 2px dashed #52b2cf;
    height: 420px;
    border-radius: 10px;
    margin-top: 60px;
    @media (min-width: 350px) and (max-width: 900px) {
      width: 100%;
      ${
        "" /* min-width:300px;
       margin-top:50px; */
      }
    }
  }

  .input-container {
    width: 50%;

    @media (min-width: 350px) and (max-width: 900px) {
      width: 100%;
    }
  }

  .uploader-container {
  }

  .input-container-duration {
    width: 25%;
    @media (min-width: 350px) and (max-width: 900px) {
      width: 100%;
    }
  }

  .input-container-select {
    width: 25%;
    @media (min-width: 350px) and (max-width: 900px) {
      width: 100%;
    }
  }

  .input-container-select-lang {
    width: 10%;
    @media (min-width: 350px) and (max-width: 900px) {
      width: 100%;
    }
  }

  .label {
    font-size: 20px;
  }

  .input-select {
    width: 100%;
    border-radius: 20px;
    background-color: #0c343a;
    color: white;
    outline: none;
    padding-right: 20px;
    border: none;
    padding: 7px;
    padding-left: 10px;
  }

  .input {
    color: white;
    background-color: #0c343a;
    width: 100%;
    ${"" /* max-width:400px; */}
    border-radius:20px;
    outline: none;
    border: none;
    padding: 5px;
    padding-left: 10px;
    display: block;
  }
`;

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getAllContestData,
  getPoems,
  getStories,
  getStoryCategories,
  userLike,
} from "./utilities/ApiCalls";
import { statusFailure } from "./Constant";
import storybf from "../Images/Stories.png";
import book from "../Images/book.png";
import "../css/Stories.css";
import Context from "../Context";

import styled from "styled-components";

const Poetries = (props) => {
  const history = useHistory();
  const { state, dispatch } = useContext(Context);
  const query = new URLSearchParams(props.location.search);
  let search = query.get("search");

  const { t } = useTranslation();

  let contestId = query.get("contest");
  let userId = query.get("userId");

  const [poetries, setPoetries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [length, setLength] = useState(1);
  const [isLike, setLike] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [getNewData, setNewData] = useState(0);
  const [loading, setloading] = useState(false);
  const [isover, setIsOver] = useState(false);

  useEffect(() => {
    let prams = { moduleType: 1 };
    getStoryCategories(prams).then((response) => {
      setCategories(response.data);
    });
  }, []);

  useEffect(() => {
    Poems();
  }, [categoryName, pageNo]);

  useEffect(() => {
    setPoetries([]);
    setPageNo(1);
  }, [categoryName, search]);

  const Poems = () => {
    let data = {};
    console.log("categoryId------------->", categoryName);
    data.pageNo = pageNo;
    data.categoryName = categoryName;
    data.search = search;
    console.log("data=======>");
    console.log(data);
    getPoems(data).then((response) => {
      const newStories = response.data;
      if (newStories.length === 0) {
        setIsOver(true);
      }
      console.log(newStories);
      setPoetries((stories) => [...stories, ...newStories]);
      setloading(false);
    });
  };

  const handelInfiniteScroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.scrollHeight &&
        !isover
      ) {
        console.log(isover);
        setloading(true);
        setPageNo((prev) => {
          console.log("Setting page from", prev, "to", prev + 1);
          return prev + 1;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#0f1817",
        }}
      >
        <Root>
          <div className="stories-container">
            {/* <div className="adventure-title">Adventure Begins..</div>
            <div className="sub-title">We write to taste life twice,in the moment and in retrospect</div>
            <button className="btn">Start Writing</button> */}
            <div className="header">
              <div className="stories">{t("Poetries.Poetries")}</div>
              <div className="select">
                <select
                  id="categoryDropdown"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  className="drop-down"
                >
                  <option value="">Genres</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="grid-container">
              {poetries.length > 0 &&
                poetries.map((data) => (
                  <Link className="cart" to={`/poem-details/${data._id}`}>
                    <div className="cart">
                      <div className="img-contiainer">
                        <img src={data.thumbnail} className="img-stories"></img>
                        <div className="hover-text">
                          <p className="content">{data.description}</p>
                        </div>
                      </div>
                      <div className="title">{data.name}</div>
                    </div>
                  </Link>
                ))}
            </div>
            {poetries.length === 0 && <div>No Poetries Found</div>}
          </div>
        </Root>
      </div>
    </Layout>
  );
};

export default Poetries;

const Root = styled.div`
  min-height: 100vh;
  background-color: #0f1817;
  height: auto;
  width: 100%;

  display: flex;
  max-width: 1300px;

  .stories {
    color: white;
    font-size: 25px;
    font-weight: bold;
  }

  .hover-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 20px;
    opacity: 0;
    overflow: hidden;
    padding: 0 10px;
    &:hover {
      opacity: 1; /* Display when hovered */
      background-color: rgba(0, 0, 0, 0.7);
    }
  }

  .content {
    letter-spacing: -0.5px; /* Adjust as needed */
    font-size: 14px; /* Adjust as needed */
    overflow: hidden;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .drop-down {
    padding: 4px;
    width: auto;
    min-width: 100px;
    border-radius: 20px;
    color: #bd2130;
  }

  .header {
    width: 100%;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    margin-top: 50px;
    @media (min-width: 1100px) {
      max-width: 1300px;
      padding-right: 75px;
    }
  }

  .thumbnail {
    width: 100%;
    height: 80%;
    border-radius: 20px;
  }

  .hover-text {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    opacity: 0;
    line-height: 1.2; /* Adjust line height as needed */
    letter-spacing: -0.5px; /* Adjust letter spacing as needed */
    overflow: hidden;
    text-align: center;
    white-space: normal;
    text-overflow: ellipsis;
    &:hover {
      opacity: 1; /* Display when hovered */
      background-color: rgba(0, 0, 0, 0.7);
    }
    @media (min-width: 350px) and (max-width: 600px) {
      font-size: 15px;
    }
  }

  .cart {
  height:245px;
    max-height: 250px;
    max-width: 350px;
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) and (max-width: 790px) {
      height:190px;
      max-height: 200px;
      max-width: 200px;
    }
    @media (min-width: 350px) and (max-width: 600px) {
      height:190px;
      max-height: 200px;
      max-width: 200px;
    }
  }

  .img-contiainer {
    height: 80%;
    width: 100%;
    position: relative;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  .img-stories {
    margin-top: 0px;
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }

  .title {
    width: 95%;
    height: 18%;
    margin-top: 5px;
    font-size: 15px;
    color: white;
    padding-left: 8px;
    text-align: start;
    margin-left: 5px;
    letter-spacing: -1px;
    line-height: 1; /* Adjusted line height to ensure proper spacing */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    -webkit-line-clamp: 2; /* Truncate after 2 lines */
  }

  .grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(
      3,
      1fr
    ); /* 3 columns, each taking 1 fraction of the available space */
    gap: 25px; /* Space between grid items */
    padding: 20px;
    padding-left: 25px;
    padding-right: 25px;
    @media (min-width: 350px) and (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .btn {
    margin-top: 25px;
    background-color: #bd2130;
    color: white;
    font-weight: semibold;
    border-radius: 20px;
  }
  .adventure-title {
    color: white;
    font-size: 30px;
    font-family: "Georgia, serif";
  }

  .sub-title {
    color: white;
    font-family: "Georgia, serif";
    @media (min-width: 350px) and (max-width: 400px) {
      font-size: 12px;
      letter-spacing: 0.3px;
    }
  }

  .stories-container {
    display: flex;
    width: 100%;
    flex-direction: column;

    align-items: center;
  }
`;

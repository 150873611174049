import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import {
  createOfflineOpenMicParticipant,
  getOfflineOpenMics,
} from "./utilities/ApiCalls";
import Layout from "../Layout/Layout";

const OpenMicOfflineParticipantForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get("id");
  const placeHolder = "placeholder";
  const [openMicData, setOpenMicData] = useState(null);

  useEffect(() => {
    const fetchOpenMicData = async () => {
      try {
        const data = await getOfflineOpenMics();
        const foundOpenMic = data.data.find((mic) => mic._id === eventId);
        if (foundOpenMic) {
          setOpenMicData(foundOpenMic);
        } else {
          console.error("Open mic not found for the given ID.");
        }
      } catch (error) {
        console.error("Error fetching open mic data:", error);
      }
    };

    fetchOpenMicData();
  }, [eventId]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]*$/, "Name can only contain letters and spaces")
      .required("Name is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
    city: Yup.string()
      .matches(/^[a-zA-Z\s]*$/, "City can only contain letters and spaces")
      .required("City is required"),
    participantType: Yup.string().required("Participant type is required"),
    language: Yup.string().required("Language is required"),
    above_18_or_below: Yup.boolean().required(
      "Above 18 or Below 18 selection is required"
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      city: "",
      participantType: "Audience",
      language: "English",
      above_18_or_below: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!openMicData) return;

      const formData = {
        ...values,
        openMicID: eventId,
        location: openMicData.location,
        payId: placeHolder,
      };

      try {
        const result = await createOfflineOpenMicParticipant(formData);
        if (result) {
          alert("Form submitted successfully!");
          console.log(result.message);
        } else {
          alert("Error submitting the form.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("An error occurred while submitting the form.");
      }
    },
  });

  return (
    <Layout>
      <Container>
        <h1>Open Mic Form</h1>
        {openMicData ? (
          <>
            <Heading2>{openMicData.name}</Heading2>
            {/* <Heading2>Event ID: {openMicData._id}</Heading2> */}
            <p>Location: {openMicData.location}</p>
            <p>Amount: {openMicData.amount}</p>
          </>
        ) : (
          <h6>Loading open mic info...</h6>
        )}
        <FormContainer onSubmit={formik.handleSubmit}>
          <Divw>
            <Label>Name:</Label>
            <Divm>
              <Input
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <Errors>{formik.errors.name}</Errors>
              ) : null}
            </Divm>
          </Divw>
          <Divw>
            <Label>Phone:</Label>
            <Divm>
              <Input
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <Errors>{formik.errors.phone}</Errors>
              ) : null}
            </Divm>
          </Divw>
          <Divw>
            <Label>City:</Label>
            <Divm>
              <Input
                type="text"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.city && formik.errors.city ? (
                <Errors>{formik.errors.city}</Errors>
              ) : null}
            </Divm>
          </Divw>
          <Divw>
            <Label>Participant Type:</Label>
            <Select
              name="participantType"
              value={formik.values.participantType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="Audience">Audience</option>
              <option value="Performer">Performer</option>
            </Select>
            {formik.touched.participantType && formik.errors.participantType ? (
              <Errors>{formik.errors.participantType}</Errors>
            ) : null}
          </Divw>
          <Divw>
            <Label>Language:</Label>
            <Select
              name="language"
              value={formik.values.language}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
            </Select>
            {formik.touched.language && formik.errors.language ? (
              <Errors>{formik.errors.language}</Errors>
            ) : null}
          </Divw>
          <Divw>
            <Label>Above 18 or Below:</Label>
            <Select
              name="above_18_or_below"
              value={formik.values.above_18_or_below}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value={true}>Above 18</option>
              <option value={false}>Below 18</option>
            </Select>
            {formik.touched.above_18_or_below &&
            formik.errors.above_18_or_below ? (
              <Errors>{formik.errors.above_18_or_below}</Errors>
            ) : null}
          </Divw>
          <Button type="submit">Submit</Button>
        </FormContainer>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  background: #0f1817;
`;
const Heading1 = styled.h1`
  color: white;
`;

const Heading2 = styled.h2`
  color: white;
`;

const Errors = styled.div`
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  color: red;
`;
const Divw = styled.div`
  width: 100%;
  max-width: 400px;
`;
const Divm = styled.div`
  margin-bottom: 20px;
`;
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 400px;
`;

const Label = styled.label`
  color: white;
  align-self: flex-start;
  margin-bottom: 8px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  max-width: 400px;
  height: 50px;
  padding: 10px;
  display: block;
  border-radius: 4px;
  border: 1px solid #0c343a;
  background-color: #0c343a;
  color: #fff;
`;

const Select = styled.select`
  width: 100%;
  height: 50px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #0c343a;
  background-color: #0c343a;
  color: #fff;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #c81919;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: darkred;
  }
`;
export default OpenMicOfflineParticipantForm;

import Layout from "../Layout/Layout";
import { useEffect, useState } from "react";
import { getPages } from "./utilities/ApiCalls";
import firstimg from "../Images/1.png";
import '../css/about.css';

export default function AboutUS() {
    const [about, setAbout] = useState({
        title: "About Us",
        content1: "Ilinke",
        content2: "Ilinke2",
    });

    
    return (
        <Layout>
            <div className="know-page" style={{textAlign:"justify"}}>
                <section className="about_section">
                    <div className="container">
                        <hr style={{ backgroundColor: 'white' }} />
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 col-md-6 col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="mt-30">
                                            <h1>{about.title}</h1>
                                        </div>

                                        <div className="about_info">
                                            <p>
                                                Welcome to our visionary realm, crafted by the synergy of artist Anusri Venkatesh, designer extraordinaire, and poet. Fueled by the poignant realization that masterpieces often languish in obscurity, Anusri embarked on a transformative journey. Witnessing books of budding creators struggle in a vast sea of unnoticed brilliance, she joined forces to chart a journey for building and growth of a community of wordsmiths beyond their geographic boundaries. Together with her team, StoryTent birthed a platform where the echoes of artistic tales find resonance with their rightful audience.
                                            </p>
                                            <p>
                                                Anusri’s commitment to bridging the gap between artists and appreciators has garnered her the prestigious Times 40 Under 40 award, while her pioneering initiative has been spotlighted in ET Newsmakers.Notably, she stands as the official literature partner of BITS Pilani 2023, a testament to her enduring dedication to unraveling the narratives that deserve to shine.
                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 border-right">
                                                <div>
                                                    <h5>287+</h5> <p>Audience</p>
                                                </div>
                                            </div>
                                            <div className="col-4 border-right">
                                                <div>
                                                    <h5>87+</h5> <p>Poems</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div>
                                                    <h5>90+</h5> <p>Stories</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-md-6 col-12 d-flex justify-content-center align-items-center">
                                <div className="border border-danger">
                                    <img src={firstimg} className="img-fluid" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about_section1">
                    <div className="container">
                        <div className="row">
                        <div className="mt-30">
                                    <h1>What We Do</h1>
                                </div>

                            <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                                <hr />
                             
                                <div className="about_info">
                                    <p>At StoryTent, we serve the realms of virtual and in-reality creativity converge, fostering a vibrant community bound by the love for storytelling. Through our innovative online portal and the immersive offline open mic events, our brand Koral, we provide a dynamic platform for storytellers and readers alike to bridge the gap between their artistic expression and recognition.
                                        Online, our portal acts as a digital canvas, enabling storytellers to weave their narratives and connect with a global audience. Offline, Koral invites participants to share their tales in a live setting, fostering genuine connections in the shared experience of storytelling. We are the architects of a community where creativity knows no boundaries, and every artist’s longing for recognition is met with open arms.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about_section1">
                    <div className="container">
                   
                        <div className="row">
                        <h1>Our Story Our Mission</h1>
                            <div className="about_info col-lg-12 col-sm-12 col-md-12 col-12">
                                <p>Our mission at StoryTent is rooted in the belief that recognition is the pinnacle achievement for every artist. The art of storytelling, boundless and formless, resides within each human being from birth. We aspire to empower individuals to create and express their tales effortlessly, eliminating the concern of going unnoticed. In our vision, everyone is a creator and finding the right audience is akin to discovering a soulmate-a profound connection we are dedicated to fostering, creating the purest relationship between storytellers and those who crave their narratives.</p>
                                </div>

                                <h1>Our Vision</h1>
                                <div className="col-lg-12 col-sm-12 col-md-12 col-12">
                              
                                <p>At StoryTent, our vision is to cultivate a worldwide community of readers and writers, transcending borders and embracing the boundless realm where storytelling knows no constraints. We are already embarked on a resolute journey to become pan-India, hosting open mics across the country and featuring the national language, Hindi, in addition to English, as a distinguished category for writing. Looking ahead, our expansion plans encompass a diverse array of regional languages, ensuring that every linguistic tapestry finds its own vibrant community. Our ultimate goal is to celebrate the richness of global expression, where every soul, regardless of origin or language, can freely share their stories and be cherished for their unique expression.</p>
                          
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

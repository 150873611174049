import Layout from "../Layout/Layout";
import { useEffect, useState, useContext, useRef } from "react";
import {
  getPoems,
  userLike,
  userFollow,
  getProfileDetails,
  getcomments,
  getSubcomments,
  getUserportfolioPoem,
  getAnyProfileDetails,
  getStoryCategories,
  getPoemDetails,
  getStoryDetails,
  DeleteStory,
  DeletePoem,
} from "./utilities/ApiCalls";
import { useParams } from "react-router";
import Constant, { statusFailure } from "./Constant";
import { getTokenDetails } from "./utilities/CommonFunction";
import Context from "../Context";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Default from "../Images/Defaultprofile.png";
import "../css/StoryDetailsNew.css";
import Heart from "../Images/heart.png";
import axios from "axios";
import styled from "styled-components";
import { Toaster, toast } from "sonner";
import {
  CommentCart,
  CommentContainer,
  Headerforcomment,
  CommentWritingSection,
  ReplyInput,
  ScrollableDiv,
} from "./Story/StoryDetailStyle";
import { LuBike, LuSend } from "react-icons/lu";
import { Scrollbars } from "react-custom-scrollbars";
import { FaRegCommentAlt } from "react-icons/fa";
import {
  Clock,
  Clock10,
  EyeIcon,
  GripVertical,
  HeartIcon,
  Link2,
  MessageCircle,
  Plus,
  Send,
  SendHorizonalIcon,
  SendIcon,
  ThumbsUp,
  Trash,
  User,
  UserPlus,
} from "lucide-react";
import { useModel } from "../hooks/modelcontex";

export default function PoemDetails(props) {
  let { id } = useParams();
  const [viewAll, setViewAll] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [commentLike, setcommentLike] = useState(false);
  const [subComment, setsubComment] = useState("");
  const [usercomments, setusercomments] = useState([]);
  const [userSubcomments, setSubusercomments] = useState({});
  const [comment, setcomment] = useState("");
  const [profile, setProfile] = useState();
  const [replyopen, setreplyopen] = useState("");
  const [ReplyOpen, setReplyOpen] = useState();
  const [Error, setError] = useState();
  const [commentopen, setcommentopen] = useState(false);
  const [poem, setpoem] = useState({});
  const [errorState, setErrorState] = useState(null);
  const [isLike, setLike] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [similarPoem, setSimilarPoem] = useState([]);
  const history = useHistory();
  const { state, dispatch } = useContext(Context);
  const [similarId, setSimilarId] = useState("");
  const [userId, setUserId] = useState("");
  const [userBadge, setUserBadge] = useState("");
  const [isAccess, setIsAccess] = useState(true);
  const [isFollow, setFollow] = useState(0);
  const [duration, setDuration] = useState();
  const [isposted, setisposted] = useState(false);
  const [issubcommentposted, setissubcommentposted] = useState(false);
  const [isInport, setIsinPort] = useState(false);
  const [fetchpoem, setfetchpoem] = useState(false);
  const [portchange, setPortChange] = useState(false);
  const [poemContent, setPoemContent] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const { t, i18n } = useTranslation();
  const [category, setcategory] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const { Myprofile, myprofile } = useModel();
  const [speech, setSpeech] = useState(false);
  const [sharelink, setShareLink] = useState(false);
  const [link, setlink] = useState("");
  const [present, setPresent] = useState(false);

  const divRef = useRef(null);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setShareLink(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const copyToClipboard = async (text) => {
    navigator.clipboard.writeText(text);
    console.log(text);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [rate, setRate] = useState(1);
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const loadVoices = () => {
      setVoices(synth.getVoices());
    };

    if (synth.onvoiceschanged !== undefined) {
      synth.onvoiceschanged = loadVoices;
    }
    loadVoices();
  }, []);

  // const handleChange = (event) => {
  //     setText(event.target.value);
  // };

  const handleVoiceChange = (event) => {
    setSelectedVoice(event.target.value);
  };

  const handleRateChange = (event) => {
    setRate(event.target.value);
  };

  const handleSpeak = (text) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      if (selectedVoice) {
        utterance.voice = voices.find((voice) => voice.name === selectedVoice);
      }
      utterance.rate = rate;

      utterance.onstart = () => {
        setMessage("Speech has started.");
        setProgress(0);
      };

      utterance.onend = () => {
        setMessage("Speech has finished.");
        setProgress(100);
      };

      utterance.onerror = (event) => {
        setMessage(`An error occurred: ${event.error}`);
        setProgress(0);
      };

      utterance.onboundary = (event) => {
        if (event.name === "word") {
          // Estimate progress based on the word index
          // const words = text.split(' ').length;
          const progressValue = (event.charIndex / text.length) * 100;
          setProgress(progressValue);
        }
      };

      window.speechSynthesis.speak(utterance);
    } else {
      alert("Sorry, your browser does not support text-to-speech.");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function convertToSpeech(text) {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    synth.speak(utterance);
  }

  const handleStop = () => {
    if ("speechSynthesis" in window) {
      window.speechSynthesis.cancel();
      setMessage("Speech has been stopped.");
      setProgress(0);
    }
  };

  const Delete = () => {
    let param = {};
    param.userId = poem.userId;
    param.id = poem._id;

    DeletePoem(param).then((response) => {
      console.log(response);
    });

    history.push("/");
  };

  async function portfolioPoemfetch() {
    try {
      const response = await getUserportfolioPoem();
      console.log("name");
      console.log(response);
      await Promise.all(
        response.data.map(async (data) => {
          if (poem._id == data.eventId) {
            setIsinPort(true);
          } else {
            setIsinPort(false);
          }
        })
      );

      if (response.data.length == 0) {
        setIsinPort(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    portfolioPoemfetch();
  }, [poem, portchange]);

  console.log(isInport);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let prams = {
      id: id,
    };
    let token = localStorage.getItem("loginDetails");

    if (similarId) {
      prams = {
        id: similarId,
      };
    }
    if (token) {
      let userDetails = getTokenDetails(token);
      if (userDetails) {
        prams.user_id = userDetails._id;
        setUserId(userDetails._id);
      }
    }

    if (state.user.userBadge) {
      setUserBadge(state.user.userBadge.title);
    }

    getPoemDetails(prams).then((response) => {
      if (response.status !== statusFailure) {
        console.log(response);
        setpoem(response.data[0]);
        const lines = response.data[0].description.split("\n");
        setPoemContent(lines);
        // console.log(response.data[0])
        setSimilarPoem(response.similarStory);
        setIsAccess(response.isAccess);

        if (response.isAccess === false || response.isBadge === true) {
          setSuccessMessage(response.message);
        }
      } else {
        setErrorState(response.message);
        setTimeout(function () {
          setErrorState("");
        }, 3000);
      }
    });
    setfetchpoem(true);
  }, [isLike, similarId, isFollow, id]);

  console.log(poem);

  const SubmitComment = async () => {
    try {
      let token = localStorage.getItem("loginDetails");

      let response = await axios.post(
        Constant.apiBasePath + `comment/${id}`,
        { description: comment },
        { headers: { token: token } }
      );
      console.log("saved data=======>");
      console.log(response);
      setcomment("");
      setisposted(true);
      return response.data;
    } catch (error) {
      console.log("internal server error", error);
    }
  };

  const SubmitSubComment = async (commentId) => {
    try {
      let token = localStorage.getItem("loginDetails");

      let response = await axios.post(
        Constant.apiBasePath + `subComment/${commentId}`,
        { description: subComment },
        { headers: { token: token } }
      );
      console.log("saved data subcomment=======>");
      console.log(response);
      setsubComment("");
      setissubcommentposted(true);
      return response.data;
    } catch (error) {
      console.log("internal server error", error);
    }
  };

  async function CommentLike(data) {
    try {
      console.log(data);
      let token = localStorage.getItem("loginDetails");
      console.log(token);
      let response = await axios.patch(
        Constant.apiBasePath + `addRemoveLike/${data}`,
        {},
        { headers: { token: token } }
      );

      console.log("save like=====>");
      console.log(response.data);
      setcommentLike(!commentLike);
      return response.data;
    } catch (error) {
      console.log("internal error", error);
    }
  }

  const Category = () => {
    getStoryCategories().then((response) => {
      console.log(response);
      response.data.map((data) => {
        if (data._id == poem.categoryId) {
          setcategory(data.name);
        }
      });
    });
  };

  useEffect(() => {
    Category();
  }, [poem]);

  useEffect(() => {
    console.log(poem._id);
    Usercomments(poem._id);
  }, [isposted, poem, commentLike, issubcommentposted]);

  async function Usercomments(id) {
    try {
      let usercommentsArray = [];
      const commentsResponse = await getcomments(id); // Assuming getcomments function returns a promise
      const commentsData = commentsResponse.data;

      // Iterate over comments and fetch their subcomments
      await Promise.all(
        commentsData.map(async (item) => {
          const subcommentsResponse = await getSubcomments(item._id); // Assuming getSubcomments function returns a promise
          const subcommentsData = subcommentsResponse.data;

          // Push comment and its subcomments to usercommentsArray
          usercommentsArray.push({
            usercomment: item,
            usersubcomments: subcommentsData,
          });
        })
      );
      usercommentsArray.sort((a, b) => {
        return (
          new Date(a.usercomment.createdAt) - new Date(b.usercomment.createdAt)
        );
      });
      // Update state with structured array
      console.log(usercommentsArray);
      setusercomments(usercommentsArray);
    } catch (error) {
      console.error("Error fetching comments and subcomments:", error);
      // Handle error appropriately
    }
  }

  useEffect(() => {
    // Dynamically set the moment locale based on the current i18n language
    moment.locale(i18n.language);
  }, [i18n.language]); // Depend on the current language

  useEffect(() => {
    getProfileDetails().then((response) => {
      if (response.status !== statusFailure) {
        setProfile(response.data.data);
      } else {
        setError(response.message);
      }
    });
  }, []);

  function like() {
    let likeRequest = {
      moduleId: poem._id,
      type: 2,
      user_id: "",
      title: poem.name,
      description: poem.description,
      thumbnail: poem.thumbnail,
    };

    console.log(likeRequest);

    userLike(likeRequest).then((response) => {
      if (response.status !== statusFailure && response.data) {
        setLike(response.data.userLike);
      }
    });
  }

  const Duration = (createdAt) => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const currentDate = Date.now();
    const differenceInMilliseconds =
      currentDate - new Date(createdAt).getTime();
    const days = Math.floor(differenceInMilliseconds / millisecondsPerDay);
    return days;
  };

  console.log(poem);

  function follow(followingId, status) {
    let userStatus = status ? 0 : 1;
    let userRequest = {
      followingId: followingId,
      status: userStatus,
    };
    userFollow(userRequest).then((response) => {
      if (response.status !== statusFailure) {
        setFollow(status);
      }
    });
  }

  const contentRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (contentRef.current && !contentRef.current.contains(event.target)) {
        setcommentopen(false);
        console.log("Clicked outside. Closing...");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddToPortfolio = async () => {
    let token = localStorage.getItem("loginDetails");

    try {
      const res = await axios.post(
        `${Constant.apiBasePath}portfolio/${id}`,
        {
          userId: poem.userId,
        }, // URL with ID
        {
          headers: {
            token: token, // Pass token in headers
          },
        }
      );
      console.log(res.data);
      toast.success("added successfully in portfolio");
      setPresent(true);
    } catch (error) {
      toast.error("already in portfolio");
      console.error("Error making the request", error);
    }
  };
  const handleDeleteFromPortfolio = async () => {
    let token = localStorage.getItem("loginDetails");

    try {
      const res = await axios.delete(
        `${Constant.apiBasePath}portfolio/removeEvent/${id}`,
        {
          headers: {
            token: token, // Pass token in headers
          },
        }
      );
      console.log(res.data);
      toast.success("Removed successfully from portfolio");

      setPresent(false);
    } catch (error) {
      toast.error("error  while deleting");
      console.error("Error making the request", error);
    }
  };

  return (
    <Layout>
      {poem && (
        <div
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            backgroundColor: "#0f1817",
            height: "auto",
          }}
        >
          <Root bgImg={poem.thumbnail}>
            {size.width > 700 ? (
              <div className="story-container">
                <div className="story-header">
                  <div className="story-heading">
                    <div className="heading">
                      <div>{poem.name}</div>
                      {!speech ? (
                        <i
                          style={{ marginLeft: "10px" }}
                          className="fa fa-volume-up default-cursor-point sound-icon"
                          onClick={(e) => {
                            handleSpeak(poem.description);
                            setSpeech(true);
                          }}
                        ></i>
                      ) : (
                        <i
                          style={{ marginLeft: "10px" }}
                          className="fa fa-pause default-cursor-point pause-icon"
                          onClick={(e) => {
                            handleStop();
                            setSpeech(false);
                          }}
                        ></i>
                      )}
                    </div>
                    <div className="user-profile">
                      <Link
                        to={
                          poem.userId === myprofile._id
                            ? `/profile`
                            : `/userprofile/${poem.userId}`
                        }
                      >
                        <div className="user-details">
                          {poem.profilePic === "" ? (
                            <img
                              alt="userprofile"
                              className="user-img"
                              src={Default}
                            ></img>
                          ) : (
                            <img
                              alt="userprofile"
                              className="user-img"
                              src={poem?.profilePic}
                            ></img>
                          )}
                          <div className="user-name">
                            <div className="name">{poem.userName}</div>
                            <div className="time">
                              {moment(poem?.createdAt)?.fromNow()}
                            </div>
                          </div>
                        </div>
                      </Link>

                      <div className="follow-button">
                        <button
                          onClick={() => {
                            follow(poem.userId, poem.userFollow);
                          }}
                          className="follow"
                        >
                          {poem.userFollow
                            ? `+${t("PoemDetails.Unfollow")}`
                            : `+${t("PoemDetails.Follow")}`}
                        </button>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="icon">
                        <div className="eye">
                          <Clock10 className=""></Clock10> {poem.duration}m
                        </div>
                        {category !== "" && (
                          <div className="gener">{category}</div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="thumbnail">
                    <img
                      src={poem?.thumbnail}
                      alt="thumbnail"
                      className="img"
                    ></img>
                  </div>
                </div>

                <div
                  style={{
                    border: "1px solid #ccc",
                    width: "100%",
                    height: "4px",
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      width: `${progress}%`,
                      height: "100%",
                      background: "green",
                    }}
                  ></div>
                </div>

                <div className="sound-setting">
                  <select
                    className="select"
                    onChange={handleVoiceChange}
                    value={selectedVoice}
                  >
                    <option value="">Eng</option>
                    {voices.map((voice, index) => (
                      <option key={index} value={voice.name}>
                        {voice.name} ({voice.lang})
                      </option>
                    ))}
                  </select>
                  <select
                    className="select"
                    value={rate}
                    onChange={handleRateChange}
                  >
                    <option value={0.5}>0.5x</option>
                    <option value={1}>1x</option>
                    <option value={1.5}>1.5x</option>
                    <option value={2}>2x</option>
                  </select>
                  {poem.userId === myprofile._id && (
                    <div onClick={() => setOpenDelete(true)}>
                      {" "}
                      <i
                        style={{ color: "white", cursor: "pointer" }}
                        class="fa fa-ellipsis-h"
                      ></i>
                    </div>
                  )}
                </div>

                {/* <hr className="hr"></hr> */}
                <div className="story-description">
                  {poem.description?.split("\n").map((str, index) => (
                    <>
                      {str}
                      <br></br>
                    </>
                  ))}
                </div>
                <div className=" story-options">
                  {poem.userLike ? (
                    <span>
                      <img
                        alt="heart"
                        onClick={like}
                        style={{
                          height: "25px",
                          width: "25px",
                          marginTop: "0px",
                          cursor: "pointer",
                        }}
                        src={Heart}
                      ></img>{" "}
                      {poem.likes}
                    </span>
                  ) : (
                    <span>
                      <HeartIcon
                        style={{ cursor: "pointer" }}
                        onClick={like}
                      ></HeartIcon>{" "}
                      {poem.likes}
                    </span>
                  )}
                  <span>
                    <MessageCircle
                      style={{ cursor: "pointer" }}
                    ></MessageCircle>{" "}
                    {usercomments.length}
                  </span>
                  <SendIcon
                    style={{ cursor: "pointer", marginTop: "2px" }}
                    onClick={() => {
                      setlink(
                        `https://www.storytent.in/poem-details/${poem._id}`
                      );
                      setShareLink(true);
                    }}
                  ></SendIcon>
                </div>
              </div>
            ) : (
              <div className="story-container-mobile">
                <div className="Stroy-header-mobile">
                  <div className="inner-box-mobile">
                    <div className="heading-mobile">
                      <div className="heading-container">
                        {poem.name}
                        {!speech ? (
                          <i
                            style={{ marginLeft: "10px" }}
                            className="fa fa-volume-up default-cursor-point sound-icon"
                            onClick={(e) => {
                              handleSpeak(poem.description);
                              setSpeech(true);
                            }}
                          ></i>
                        ) : (
                          <i
                            style={{ marginLeft: "10px" }}
                            className="fa fa-pause default-cursor-point pause-icon"
                            onClick={(e) => {
                              handleStop();
                              setSpeech(false);
                            }}
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="user-profile-mobile">
                      <Link
                        to={
                          poem.userId === myprofile._id
                            ? `/profile`
                            : `/userprofile/${poem.userId}`
                        }
                        className="user-details"
                      >
                        {poem.profilePic === "" ? (
                          <img
                            alt="userprofile"
                            className="user-img"
                            src={Default}
                          ></img>
                        ) : (
                          <img
                            alt="userprofile"
                            className="user-img"
                            src={poem?.profilePic}
                          ></img>
                        )}
                        <div className="user-name">
                          <div className="name">{poem.userName}</div>
                          <div className="time">
                            {moment(poem?.createdAt)?.fromNow()}
                          </div>
                        </div>
                      </Link>
                      <div className="follow-button">
                        <button
                          onClick={() => {
                            follow(poem.userId, poem.userFollow);
                          }}
                          className="follow-mobile"
                        >
                          {poem.userFollow ? "Unfollow" : "+Follow"}
                        </button>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className="icon">
                        <div className="eye">
                          <Clock10 className=""></Clock10> {poem.duration}m
                        </div>
                        {category !== "" && (
                          <div className="gener">{category}</div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    border: "1px solid #ccc",
                    width: "100%",
                    height: "4px",
                    marginTop: "1px",
                  }}
                >
                  <div
                    style={{
                      width: `${progress}%`,
                      height: "100%",
                      background: "green",
                    }}
                  ></div>
                </div>

                <div className="sound-setting">
                  <select
                    className="select"
                    onChange={handleVoiceChange}
                    value={selectedVoice}
                  >
                    <option value="">Eng</option>
                    {voices.map((voice, index) => (
                      <option key={index} value={voice.name}>
                        {voice.name} ({voice.lang})
                      </option>
                    ))}
                  </select>
                  <select
                    className="select"
                    value={rate}
                    onChange={handleRateChange}
                  >
                    <option value={0.5}>0.5x</option>
                    <option value={1}>1x</option>
                    <option value={1.5}>1.5x</option>
                    <option value={2}>2x</option>
                  </select>
                  {poem.userId === myprofile._id ? (
                    <p
                      style={{
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: "#0f1817",
                      }}
                    >
                      {present ? (
                        <button
                          style={{
                            color: "white",
                            height: "20px",
                            cursor: "pointer",
                            border: "none",
                            fontSize: "30px",
                            borderRadius: "20px",
                            backgroundColor: "#0f1817",
                            position: "relative",
                            bottom: "-18px",
                          }}
                          onClick={handleDeleteFromPortfolio}
                        >
                          -
                        </button>
                      ) : (
                        <button
                          style={{
                            color: "white",
                            height: "20px",
                            cursor: "pointer",
                            border: "none",
                            fontSize: "30px",
                            borderRadius: "20px",
                            backgroundColor: "#0f1817",
                            position: "relative",
                            bottom: "-18px",
                          }}
                          onClick={handleAddToPortfolio}
                        >
                          +
                        </button>
                      )}
                    </p>
                  ) : (
                    ""
                  )}

                  {poem.userId === myprofile._id && (
                    <div onClick={() => setOpenDelete(true)}>
                      {" "}
                      <i
                        style={{ color: "white" }}
                        class="fa fa-ellipsis-h"
                      ></i>
                    </div>
                  )}
                </div>
                <div className="story-description-mobile">
                  {poem.description?.split("\n").map((str, index) => (
                    <>
                      {str}
                      <br></br>
                    </>
                  ))}
                </div>
                <div className=" story-options">
                  {poem.userLike ? (
                    <span>
                      <img
                        alt="heart"
                        onClick={like}
                        style={{
                          height: "25px",
                          width: "25px",
                          marginTop: "0px",
                          cursor: "pointer",
                        }}
                        src={Heart}
                      ></img>{" "}
                      {poem.likes}
                    </span>
                  ) : (
                    <span>
                      <HeartIcon
                        style={{ cursor: "pointer" }}
                        onClick={like}
                      ></HeartIcon>{" "}
                      {poem.likes}
                    </span>
                  )}
                  <span>
                    <MessageCircle></MessageCircle> {usercomments.length}
                  </span>
                  <SendIcon
                    style={{ cursor: "pointer", marginTop: "2px" }}
                    onClick={() => {
                      setlink(
                        `https://www.storytent.in/poem-details/${poem._id}`
                      );
                      setShareLink(true);
                    }}
                  ></SendIcon>
                </div>
              </div>
            )}

            <div className="comment-section">
              <form className="form">
                <input
                  value={comment}
                  onChange={(e) => setcomment(e.target.value)}
                  placeholder={t("PoemDetails.PlaceHolder")}
                  className="comment-input"
                ></input>
                <button
                  type="button"
                  onClick={() => SubmitComment()}
                  className="send-btn"
                >
                  <SendHorizonalIcon></SendHorizonalIcon>
                </button>
              </form>
              <div className="comments">
                {usercomments.length > 0 &&
                  usercomments &&
                  usercomments.map((data) => {
                    const duration = Duration(data.usercomment.createdAt) || 0;
                    console.log("duration=====>");
                    console.log(duration);
                    return (
                      <div className="comment-cart">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="user-comment">
                            <img
                              className="profile-img"
                              alt="profile"
                              src={data.usercomment.userProfilePicture}
                            ></img>
                            <div className="profile-name">
                              <span
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                {data.usercomment.userName}
                              </span>
                              {/* <span style={{fontSize:"10px",marginTop:"-5px"}}>  {moment(data.usercomment?.createdAt)?.fromNow()}</span> */}
                            </div>
                          </div>
                          <span
                            className="likeicon"
                            onClick={() => CommentLike(data.usercomment._id)}
                          >
                            <ThumbsUp></ThumbsUp>
                          </span>
                        </div>
                        <div className="comment-text">
                          {data.usercomment.description}
                        </div>
                        <div className="comment-icons">
                          <div>
                            {moment(data.usercomment?.createdAt)?.fromNow()}
                          </div>
                          <div>
                            {data.usercomment.likes.length}{" "}
                            {t("PoemDetails.Likes")}
                          </div>
                          <div
                            onClick={() => {
                              setReplyOpen(data.usercomment._id);
                            }}
                          >
                            {t("PoemDetails.Reply")}
                          </div>
                        </div>
                        {data.usersubcomments.length > 0 ? (
                          replyopen !== data.usercomment._id ? (
                            <div
                              className="view"
                              onClick={() => {
                                setViewAll(data.usercomment._id);
                                setreplyopen(data.usercomment._id);
                              }}
                            >
                              {t("PoemDetails.ViewReply")}
                            </div>
                          ) : (
                            <div
                              className="view"
                              onClick={() => setreplyopen("")}
                            >
                              {t("PoemDetails.HideReply")}
                            </div>
                          )
                        ) : null}
                        {ReplyOpen === data.usercomment._id && (
                          <form className="subform">
                            <input
                              value={subComment}
                              onChange={(e) => setsubComment(e.target.value)}
                              placeholder="Add a comment"
                              className="subcomment-input"
                            ></input>
                            <button
                              type="button"
                              onClick={() =>
                                SubmitSubComment(data.usercomment._id)
                              }
                              className="subsend-btn"
                            >
                              {t("PoemDetails.Reply")}
                            </button>
                          </form>
                        )}

                        {replyopen &&
                          viewAll === data.usercomment._id &&
                          data.usersubcomments.length > 0 &&
                          data.usersubcomments.map((data) => {
                            const durationsub = Duration(data.createdAt);

                            return (
                              <div
                                style={{
                                  marginLeft: "70px",
                                  marginTop: "20px",
                                  height: "auto",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <img
                                      style={{
                                        borderRadius: "100px",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                      alt=""
                                      src={data.userProfilePicture}
                                    ></img>
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    >
                                      {data.userName}
                                    </span>
                                  </div>
                                  <span onClick={() => CommentLike(data._id)}>
                                    <i
                                      class={
                                        "fa fa-thumbs-o-up default-cursor-point"
                                      }
                                    ></i>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "35px",
                                    marginTop: "-10px",
                                    color: "white",
                                  }}
                                >
                                  {data.description}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <div style={{}}>
                                    {durationsub} {t("PoemDetails.Days")}
                                  </div>
                                  <div style={{}}>
                                    {data.likes.length} {t("PoemDetails.Likes")}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            </div>

            {sharelink && (
              <div ref={divRef} className="share-box">
                <div
                  onClick={() => setShareLink(false)}
                  style={{
                    position: "absolute",
                    right: "0px",
                    cursor: "pointer",
                  }}
                >
                  <Plus
                    style={{
                      transform: "rotate(45deg)",
                      color: "white",
                      cursor: "pointer",
                    }}
                  ></Plus>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                    fontSize: "20px",
                  }}
                >
                  {t("PoemDetails.Share")}
                </div>
                <div className="share-link-icon">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      copyToClipboard(link);
                      setShareLink(false);
                    }}
                    className="icon-container"
                  >
                    <Link2></Link2>
                  </span>
                </div>
                <div style={{ textAlign: "center", color: "white" }}>
                  {t("PoemDetails.CopyLink")}
                </div>
              </div>
            )}

            {openDelete && (
              <div className="Delete-Pop-up">
                <div
                  onClick={() => setOpenDelete(false)}
                  style={{
                    position: "absolute",
                    top: "2px",
                    right: "2px",
                    transform: "rotate(45deg)",
                    padding: "2px",
                    color: "white",
                  }}
                >
                  <Plus></Plus>
                </div>
                <div
                  className="delete"
                  onClick={() => Delete()}
                  style={{
                    display: "flex",
                    gap: "20px",
                    cursor: "pointer",
                    color: "red",
                  }}
                >
                  <Trash></Trash>{" "}
                  <span style={{ color: "white" }}>
                    {t("PoemDetails.Delete")}
                  </span>
                </div>
              </div>
            )}
          </Root>
        </div>
      )}
    </Layout>
  );
}

const Root = styled.div`
  min-height: 100vh;
  height: auto;
  margin-top: -16px;
  background-color: #0f1817;
  display: flex;
  width: 100%;
  max-width: 1300px;
  flex-direction: column;

  ${"" /* justify-content:center; */}
  align-items:center;

  .share-link-icon {
    display: flex;
    justify-content: center;
    justify-content: center;
  }

  .share-box {
    width: 300px;
    height: 130px;
    border-radius: 10px;
    background-color: #023020;
    position: fixed;
    top: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Delete-Pop-up {
    width: 200px;
    height: 100px;
    background-color: #07262b;
    border-radius: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    transform: translate(-50%, -50%);
  }

  .delete:hover {
    background-color: gray;
    padding: 2px;
    width: 150px;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .icon-container {
    background-color: #e6edea;
    padding: 10px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 100px;
  }

  .gener {
    font-size: 12px;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    color: white;
  }

  .eye {
    color: white;
    font-size: 12px;
  }

  .likeicon {
    color: white;
    width: 25px;
    height: 25px;
    margin-top: 12px;
    margin-right: 20px;
  }

  .user-name {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .like {
    width: 25px;
    height: 25px;
  }

  .view {
    margin-left: 75px;
    margin-top: 10px;
  }

  ${
    "" /* .story-heading{
  position:absolute;
  bottom:0;
  left:0;
} */
  }

  .comment-icons {
    display: flex;
    margin-left: 60px;
    font-size: 12px;
    gap: 12px;
  }
  .hr {
    border: 1px solid;
    opacity: 0.5;
  }

  .comment-text {
    color: #7a8b8c;
    margin-left: 55px;
    color: white;
    margin-top: -23px;
    font-size: 16px;
    height: auto;
  }

  .profile-name {
    color: white;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
  }

  .sound-setting {
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 20px;
    margin-top: 20px;
    @media (min-width: 350px) and (max-width: 700px) {
      margin-right: 20px;
    }
  }

  .select {
    width: 80px;
    background-color: gray;
    opacity: 0.5;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
    border: none;
    color: white;
    border-radius: 20px;
    text-align: center;
  }

  .story-options {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    color: white;
    @media (min-width: 350px) and (max-width: 700px) {
      margin-left: 30px;
    }
  }

  ${"" /* mobile responsive */}

  .follow-mobile {
    padding-left: 5px;
    padding-right: 5px;
    background: transparent;
    outline: none;
    border: 1px solid white; /* Added a solid border with 1px width */
    color: white;
    margin-top: 5px;
  }

  .desc {
    color: white;
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }

  .text {
    margin-top: 20px;
    margin-left: 20px;
    color: white;
  }

  .user-profile-mobile {
    display: flex;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
  }

  .drop {
    position: absolute;
    width: 390px;
    height: 100px;
    background-color: #263a38;
    z-index: 100;
  }

  .heading-mobile {
    display: flex;
    color: white;
    font-size: 18px;
    margin-bottom: 10px;
    margin-left: 24px;
    line-height: 1.2;
    letter-spacing: -0.3px;

    @media (min-width: 350px) and (max-width: 450px) {
      font-size: 18px;
    }
  }

  .inner-box-mobile {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80%;
    max-width: 400px;
    min-height: 120px;
    padding-bottom: 20px;
    height: auto;
    @media (min-width: 350px) and (max-width: 600px) {
      width: 85%;
      max-width: 350px;
      min-height: 120px;
    }
  }

  .story-container-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .img-cover {
    width: 100%;
    height: 100%;
  }

  .story-description-mobile {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    min-height: 250px;
    height: auto;
    text-align: justify;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    word-wrap: break-word;
    white-space: normal;
  }

  .Stroy-header-mobile {
    position: relative;
    width: 100%;
    height: 250px;
    background: transparent;
    background-image: ${(props) => `url(${props.bgImg})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-item: end;
    justify-content: end;
  }

  .Stroy-header-mobile::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${"" /* desktop responsive */}
  .comment-input {
    width: 100%;
    border-radius: 20px;
    outline: none;
    background-color: #0f1817;
    border: 1px solid #263a38;
    padding: 5px;
    padding-left: 10px;
    color: white;
    display: initial;
  }

  .subcomment-input {
    width: 100%;
    border-radius: 20px;
    outline: none;
    background-color: #0f1817;
    border: 1px solid #263a38;
    padding: 5px;
    padding-left: 10px;
    color: white;
    margin-left: 75px;
    margin-top: 10px;

    @media (min-width: 350px) and (max-width: 450px) {
      font-size: 11px;
    }
  }

  .profile-img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
  }
  .comment-cart {
    width: 100%;
    height: auto;
  }
  .comments {
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .send-btn {
    position: absolute;
    right: 7px;
    top: 4px;
    border: none;
    outline: none;
    background-color: #0f1817;
    color: white;
  }

  .subsend-btn {
    position: absolute;
    right: 15px;
    top: 13px;
    border: none;
    outline: none;
    background-color: #0f1817;
    color: white;

    @media (min-width: 350px) and (max-width: 450px) {
      right: 8px;
      top: 15px;
      font-size: 10px;
    }
  }

  .story-description {
    padding: 5px;
    margin-bottom: 20px;
    ${"" /* padding-left:10px; */}
    padding-right:20px;
    color: white;
    text-align: justify;
    height: auto;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    font-size: 20px;
    min-height: 200px;
    word-wrap: break-word;
    white-space: normal;
  }

  .icon {
    margin-top: 10px;
    gap: 15px;
    display: flex;
    margin-left: 7px;
    @media (min-width: 350px) and (max-width: 700px) {
      margin-left: 25px;
    }
  }

  .heading {
    display: flex;
    color: white;
    font-size: 24px;
    margin-left: 1px;
    line-height: 1;
    letter-spacing: -0.4px;
  }

  .heading-container {
    ${"" /* display: inline-flex; */}
    align-items: center;
    gap: 10px;
  }

  .name {
    color: white;
    line-height: 1.2;
    letter-spacing: -0.4px;
    margin-top: 5px;
    @media (min-width: 350px) and (max-width: 450px) {
      font-size: 12px;
      margin-top: 5px;
    }

    @media (min-width: 701px) and (max-width: 800px) {
      font-size: 14px;
    }
  }
  .follow {
    padding-left: 5px;
    padding-right: 5px;
    background-color: #0f1817;
    outline: none;
    z-index: 1000;

    border: 1px solid white; /* Added a solid border with 1px width */
    color: white; /* Changed color to white for better contrast */
    margin-top: 5px;
  }
  .time {
    margin-top: -6px;
    font-size: 10px;
    color: white;
  }
  .user-img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  .user-details {
    display: flex;
    gap: 10px;
  }

  .story-container {
    width: 80%;
    height: auto;
    margin-top: 50px;
  }

  .sound-icon {
    margin-left: 5px;
  }
  .img {
    width: 250px;
    height: 160px;
    border-radius: 20px;
  }

  .story-header {
    width: 100%;
    height: 200px;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    ${"" /* align-items:center; */}
  }

  .comment-section {
    width: 85%;
    padding-left: 25px;
    padding-right: 25px;
    ${"" /* min-height:5000px; */}
    height:auto;

    @media (min-width: 350px) and (max-width: 700px) {
      width: 100%;
    }
  }
  .form {
    position: relative;
  }

  .subform {
    position: relative;
    display: flex;
    justify-content: end;
  }

  .user-comment {
    display: flex;
    gap: 10px;
    padding: 10px;
  }
  .user-profile {
    display: flex;
    margin-top: 20px;
    gap: 30px;
  }
`;

import React, { useState } from "react";
import Layout from "../Layout/Layout";
import styled from "styled-components";
import { deleteAccount } from "./utilities/ApiCalls";
import { Toaster, toast } from "sonner";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { getTokenDetails } from "./utilities/CommonFunction";
// Import the useNavigate hook

export const DeleteAccount = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);

  const handleCancel = () => {
    // Navigate to the homepage
    // Replace this with the actual navigation logic
    window.location.href = "/";
  };

  const handleDel = async () => {
    try {
      // Show a loading spinner while deleting the account
      setLoader(true);
      const token = await localStorage.getItem("loginDetails");
      console.log(token);
      let { _id } = getTokenDetails(token);
      let userId = _id;
      const result = await deleteAccount(userId);
      console.log("User account deleted", result);
      setTimeout(() => {
        // Remove token from localStorage
        localStorage.removeItem("loginDetails");

        setLoader(false);

        // Redirect to login page
        toast.success(t("Settings.DeleteSuccess")) // You can handle the success response here
      }, 2000);
      window.location.href = "/login";
    } catch (error) {
      console.error("Error deleting user account", error); // Handle the error if deletion fails
    }
  };
  return (
    <Layout>
      <Root>
        <div className="del-container">
          <h1 className="head" style={{ textAlign: "center" }}>
            {t("Settings.DeleteAccount")}
          </h1>
          <p className="text">{t("Settings.AreYouSureDelete")}</p>
          <div className="del-containerbtn">
            <button className="cancel-btn" onClick={handleCancel}>
              {t("Settings.Cancel")}
            </button>
            <button className="del-btn" onClick={handleDel}>
              {t("Settings.DeleteButton")}
            </button>
          </div>
        </div>
        {loader && (
          <div className="overlay">
            <div className="popup">
              <ClipLoader color="white" size={50} />
              <h1>{t("Settings.Deleting")}</h1>
            </div>
          </div>
        )}
      </Root>
      <Toaster />
    </Layout>
  );
};
const Root = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;

  .del-container {
    height: 85vh;
    display: flex;
    flex-direction: column;

    align-items: center;
    padding: 10px;
  }

  .text {
    text-align: center;
    color: white;
    font-size: 24px;
  }
  .del-containerbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .cancel-btn {
    width: 90px;
    height: 100%;
    background-color: #4b4747;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 6px;
    font-size: 18px;
    font-weight: 500;
  }
  .del-btn {
    width: 90px;
    height: 100%;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 6px;
    font-size: 18px;
    font-weight: 500;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it appears above other content */
  }

  .popup {
    background-color: #222; /* Dark background for the popup */
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: white;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5); /* Adds a nice shadow */
  }

  .popup h1 {
    margin-top: 20px;
    font-size: 20px;
  }
`;

export default DeleteAccount;

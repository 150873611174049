import React, { useEffect, useState } from 'react'
import Layout from '../Layout/Layout'
import styled from 'styled-components'
import axios from 'axios';
import Constant from './Constant';
import $ from 'jquery';
import {  getProfileDetails, updateUserProfile } from './utilities/ApiCalls';
import { PencilIcon } from 'lucide-react';
export const EditProfile = () => {

const[profile,setprofile]=useState()

const [updateForm, setUpdateForm] = useState({
    name: '',
    email: '',
    dob: '',
    gender: '',
    countryId: '',
    stateId: '',
    cityId: '',
    profilePic:''
});

useEffect(()=>{
    getProfile();
},[])

useEffect(() => {
    if (profile) {
      setUpdateForm({
        name: profile.name || '',
        email: profile.email || '',
        dob: profile.dob || '',
        gender: profile.gender || '',
        countryId: profile.countryId || '',
        stateId: profile.stateId || '',
        cityId: profile.cityId || '',
        profilePic: profile.profilePic || ''
      });
    }
  }, [profile]);

  console.log(profile)
  console.log(updateForm)

    const getProfile=()=>{
        getProfileDetails().then((response)=>{
             setprofile(response.data.data)
        })
      
    }

  
    function handleFormChange(e) {
        setUpdateForm({
            ...updateForm,
            [e.target.name]: e.target.value
        })
    }


    function handleFormSubmit(e) {
        e.preventDefault();
        console.log("submit.....")
        console.log(updateForm)
        updateUserProfile(updateForm).then(response => {
            console.log(response)
            if (response.status === Constant.statusSuccess) {
               
                setTimeout(() => window.location.reload(), 3000);
            } else {
           
            }
        });
    }

    
    function changeProfilePicture(event) {
        try {
            let token = localStorage.getItem('loginDetails');
            let image = event.target.files[0];
            const form_data = new FormData();
           
            let extension = image.name.split('.').pop();
            if(extension !== 'jpg' && extension !== 'png' && extension !== 'JPG' && extension !== 'PNG') {
                $("#error-msg").text('Only jpg and png file type are allow!');
                // $("#error-msg").css("color", "red");
                return false;
            }
                
            form_data.append('userProfile', image, image.name);         
                
            axios.post(Constant.apiBasePath + 'user/updateProfilePic', form_data, { headers: { 'token': token }}).then(response => {
            const getResults = response.data.message;
            
                if(response.data.status === Constant.statusSuccess) {
                    $("#success-msg").text('Your profile pic uploaded successfully.');
                    setTimeout(function() {
                        window.location.reload();
                    }, 3000);
                }
                else {
                    $("#error-msg").text(getResults);
                }

            });
        }
        catch(err) {
            console.log(err);
        }

    }

  return (
   <Layout>
    <div style={{width:"100%",justifyContent:"center",display:"flex",alignItems:"center",backgroundColor:"#0f1817"}}>
     <Root>
    {profile && <div className='profile-container'>
                  <div className='profile-image-container'>
                      <img  
                      alt='profile'
                      className='profile-img'
                      src={profile.profilePic}></img>  
                      <div onClick={changeProfilePicture} className='edit'><PencilIcon></PencilIcon></div> 
                      <input id="file-input" className='profile-img-input' type="file" onChange={changeProfilePicture} accept="image/png, image/jpg"/>
                  </div>

                   <form 
                   onChange={handleFormChange}
                   className='form'>
                      <div className='content'>
                        <label  
                         className='label'>Name</label>
                        <input 
                         name="name" 
                        defaultValue={profile.name}
                        className='input'></input>
                      </div>
                      <div className='content'>
                        <label className='label'>DOB</label>
                        <input 
                         type="date"
                         defaultValue={profile.dob}
                         name="dob"
                        className='input'
                        // onChange={handleInputChange}
                        placeholder='dd-mm-yyyy'
                       ></input>
                      </div>
                      <div className='content'>
                        <label className='label'>Contact Number</label>
                        <input 
                        name="mobile"
                        className='input'></input>
                      </div>
                      <div className='content'>
                        <label 
                        className='label'>Email Id</label>
                        <input 
                        name="email"
                        defaultValue={profile.email}
                        className='input'></input>
                      </div>
                     <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
                      <button type="submit" onClick={ handleFormSubmit } className='btn'>Update</button>
                      </div>
                   </form>
    </div>}
     </Root>
     </div>
   </Layout>
  )
}


const Root=styled.div`
min-height:100vh;
height:auto;
background-color:#0f1817;
display:flex;
${'' /* max-width:1300px; */}
width:100%;
max-width:1400px;
justify-content:center;

.form{
     margin-top:100px;
     width:80%;
     display:flex;
     flex-direction:column;
     gap:30px;
}

.btn{
    width:100px;
    height:40px;
    background-color:#5a6f6d;
}

.input{
    width:100%;
    background-color:#263a38;
    border:none;
    outline:none;
    padding:5px;
    border-radius:20px;
    display: block;
    color:white;
    padding-left:10px;
    padding-right:10px;
}

.label{
    color:white;
    margin-left:10px;
}

.content:{
    width:100%;
}

.main{
    padding:20px;
    padding-left:40px;
}

.profile-img{
height: 100%;
width: 100%;
object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
border-radius: 50%; /* Ensure the image itself is also a circle */
position: relative;
 z-index: 1; /* Ensure the image is above the input */
}

.edit{
   background:white;
   height:30px;
    width:30px;
    border-radius:20px;
    padding:5px;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2; /* Ensure the edit icon is above everything else */
  cursor: pointer; /* Change cursor to pointer */
}

.profile-image-container{
    height: 150px;
  width: 150px;
  border-radius: 50%; /* 50% to make it a perfect circle */
  position: relative;

}


.profile-container{
   display:flex;
   width:100%;
   align-items:center;
   justify-content:center;
   flex-direction:column;
}

.profile-img-input{
height: 100%;
width: 100%;
position: absolute;
top: 0;
left: 0;
opacity: 0; 
cursor: pointer; 
z-index: 3; 
}

.privacy-text{
  color:white;
  font-weight:bold;
  font-size:25px;
}

.privacy-subtext{
    color:white;
    font-size:20px;
    margin-top:50px;
}

.cart{
  padding:20px;
  display:flex;
  gap:20px;
  color:white;
  padding-left:30px;
}

.cart:hover{
  background-color:gray;
  border-radius:10px;

}

.vr{
    height:100%;
    border-left: 1px solid white; /* Adjust the color and width as needed */

}

.setting-container{
    display:flex;
    width:100%;
}

.side-bar{
    height:100%;
    width:40%;
    flex-basis:1/3;
    max-width:300px;
    padding-left:10px;
    padding-right:10px;

    
@media (min-width: 350px) and (max-width: 700px) {
  max-width:100px;
}
   
}

.title{
    @media (min-width: 350px) and (max-width: 700px) {
        display: none;
}
   
   
}

.main{
    height:100%;
    flex-basis:2/3;
    width:80%;

}

`